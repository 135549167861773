define("vts-ember/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nOyAUaw5",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"map-index\",null,[[\"index\",\"showLabels\",\"showTraffic\"],[[20,[\"contentFiltered\"]],[20,[\"showLicensePlates\"]],[20,[\"showTraffic\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"project\",\"project\"]]],null,{\"statements\":[[4,\"if\",[[20,[\"project\",\"project\",\"advancedMap\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"well\"],[7],[0,\"\\n      \"],[4,\"toggle-button\",null,[[\"checked\"],[[20,[\"showLicensePlates\"]]]],{\"statements\":[[0,\"Show license plates\"]],\"parameters\":[]},null],[0,\"\\n      \"],[4,\"toggle-button\",null,[[\"checked\"],[[20,[\"showTraffic\"]]]],{\"statements\":[[0,\"Show traffic\"]],\"parameters\":[]},null],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"btn-group pull-right\"],[9,\"data-toggle\",\"buttons-radio\"],[7],[0,\"\\n        \"],[4,\"button-group\",null,[[\"name\",\"selection\",\"value\"],[\"selection\",[20,[\"selection\"]],\"all\"]],{\"statements\":[[0,\"All\"]],\"parameters\":[]},null],[0,\"\\n        \"],[4,\"button-group\",null,[[\"name\",\"selection\",\"value\"],[\"selection\",[20,[\"selection\"]],\"online\"]],{\"statements\":[[0,\"Online\"]],\"parameters\":[]},null],[0,\"\\n        \"],[4,\"button-group\",null,[[\"name\",\"selection\",\"value\"],[\"selection\",[20,[\"selection\"]],\"offline\"]],{\"statements\":[[0,\"Offline\"]],\"parameters\":[]},null],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "vts-ember/templates/index.hbs"
    }
  });

  _exports.default = _default;
});