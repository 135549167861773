define("vts-ember/controllers/devices", ["exports", "vts-ember/controllers/tables"], function (_exports, _tables) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _tables.default.extend({
    application: Ember.inject.controller(),
    currentUser: Ember.inject.service(),
    queryParams: ['search', 'sortWith', 'order'],
    search: null,
    sortBy: 'serial',
    sortAscending: true,
    filterParams: ['serial', 'fwVersion', 'fwBuild', 'licensePlate', 'zone', 'homeDepot', 'projectId', 'stateTooltip'],
    allSelected: false,
    isEditable: false,
    url: function () {
      return this.get('target').url;
    }.property('target'),
    getSelectedDeviceIds: function getSelectedDeviceIds() {
      var selectedIds = [];
      this.store.peekAll('device').filterBy('isSelected', true).forEach(function (device) {
        selectedIds.push(device.get('id'));
      });
      return selectedIds;
    },
    clearSelection: function clearSelection() {
      this.store.peekAll('device').filterBy('isSelected', true).forEach(function (device) {
        device.set('isSelected', false);
      });
    },
    _isCheckedChanged: function () {
      var filteredDevices = this.get("filteredContent");

      if (this.get("allSelected")) {
        filteredDevices.forEach(function (device) {
          device.set('isSelected', true);
        });
      } else {
        filteredDevices.forEach(function (device) {
          device.set("isSelected", false);
        });
      }
    }.observes('allSelected'),

    /* ugly bunch of functions used to display search direction arrows on the correct column only */
    sortBySerial: function () {
      return this.get('sortBy') == 'serial';
    }.property('sortBy'),
    sortByFwBuild: function () {
      return this.get('sortBy') == 'fwBuild';
    }.property('sortBy'),
    sortByLicensePlate: function () {
      return this.get('sortBy') == 'licensePlate';
    }.property('sortBy'),
    sortByZone: function () {
      return this.get('sortBy') == 'zone';
    }.property('sortBy'),
    sortByHomeDepot: function () {
      return this.get('sortBy') == 'homeDepot';
    }.property('sortBy'),
    sortByProjectId: function () {
      return this.get('sortBy') == 'projectId';
    }.property('sortBy'),
    sortByStateTooltip: function () {
      return this.get('sortBy') == 'stateTooltip';
    }.property('sortBy'),
    actions: {
      deleteDevices: function deleteDevices() {
        var selectedDevices = this.store.peekAll('device').filterBy('isSelected', true);

        if (selectedDevices.length > 0) {
          var serials = selectedDevices.map(function (item) {
            return item.get('serial');
          }).join(', ');

          if (window.confirm("Are you sure you want to delete the selected ".concat(selectedDevices.length, " devices? (").concat(serials, ")"))) {
            selectedDevices.forEach(function (device) {
              device.deleteRecord();
              device.save().then(function () {
                console.log("deleted " + device);
              }, function (response) {
                /* in this state the tables need to be refreshed, ember does not do that automatically.
                 * leaving the error handling very basic here.
                 */
                device.rollbackAttributes();
              });
            });
          }
        }
      }
    }
  });

  _exports.default = _default;
});