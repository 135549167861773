define("vts-ember/models/driver-datum", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    currentUser: Ember.inject.service(),
    device: _emberData.default.belongsTo('device', {
      async: true
    }),
    value: _emberData.default.attr('number'),
    low: _emberData.default.attr('number'),
    medium: _emberData.default.attr('number'),
    high: _emberData.default.attr('number'),
    mileage: _emberData.default.attr('number'),
    valueRounded: function () {
      if (this.get('value') != undefined) {
        return Math.round(this.get('value') * 10000) / 100;
      }

      return 0;
    }.property('value'),
    distance: function () {
      var unit = this.get('currentUser.setting.distanceUnit');
      var dist = this.get('mileage');

      if (unit == 'km') {
        dist = dist / 1000.0;
      } else {
        dist = dist / 1609.34;
      }

      return dist.toFixed(3);
    }.property('mileage')
  });

  _exports.default = _default;
});