define("vts-ember/components/video-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VideoRowComponent = Ember.Component.extend({
    tagName: 'tr'
  });
  var _default = VideoRowComponent;
  _exports.default = _default;
});