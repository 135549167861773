define("vts-ember/controllers/live-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    cameraOptionsE342: [{
      label: 'Camera 1',
      value: 1
    }, {
      label: 'Camera 2',
      value: 2
    }],
    cameraOptionsE340: [{
      label: 'Camera 1',
      value: 1
    }],
    cameraOptions: function () {
      if (this.get('model.camera2')) {
        return this.cameraOptionsE342;
      } else {
        return this.cameraOptionsE340;
      }
    }.property('model.camera2'),
    cameraSource1: null,
    cameraSource2: null,
    cameraSource: function () {
      if (this.get('camera') == 2) {
        return this.get('cameraSource2');
      } else {
        return this.get('cameraSource1');
      }
    }.property('camera', 'cameraSource1', 'cameraSource2'),
    camera: 1,
    requestRunning: false,
    requestButtonLabel: function () {
      if (this.get('requestRunning')) {
        return this.get('remainingSecs');
      } else {
        return 'Request LiveCheck';
      }
    }.property('requestRunning', 'remainingSecs'),
    isLoading: function () {
      return this.get('requestRunning') && this.get('cameraSource') == null;
    }.property('cameraSource', 'requestRunning'),
    errorMessage: function () {
      if (this.get('model.state') == 'offline') {
        return "Device is offline";
      } else if (this.get('model.state') == 'disconnected') {
        return "Device lost connection";
      } else if (this.get('model.state') == 'online') {
        if (this.get('model.operatingMode') == 'ping') {
          return "Device is in ping mode";
        } else if (this.get('model.operatingMode') == 'maintenance') {
          return "Device is in maintenance mode";
        } else if (Date.now() - this.get('model.lastConnected') > 30000) {
          return "Device is currently re-establishing cellular connection";
        }
      }

      return null;
    }.property('model.state', 'model.lastConnected', 'model.operatingMode', 'now'),
    fetchLiveImage: function fetchLiveImage() {
      var camera = this.get('camera');
      var this_ = this;
      var last_modified = this.get('camera' + camera + '_modified_at');
      var xhr = new XMLHttpRequest();
      xhr.open('GET', '/live_check/' + this.get('model.serial') + '?camera=' + camera);
      xhr.responseType = "blob";

      if (last_modified != null) {
        xhr.setRequestHeader('If-Modified-Since', last_modified);
      }

      this.get('session').authorize('authorizer:devise', function (headerName, headerValue) {
        var headers = {};
        headers[headerName] = headerValue;
        xhr.setRequestHeader(headerName, headerValue);
      });

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          var last_modified = this.getResponseHeader("Last-Modified");
          this_.set('camera' + camera + '_modified_at', last_modified);
          var urlCreator = window.URL || window.webkitURL;
          var imageUrl = urlCreator.createObjectURL(this.response);
          this_.set('cameraSource' + camera, imageUrl);
        } else if (xhr.readyState === 4 && xhr.status === 404) {
          this_.set('cameraSource' + camera, null);
          this_.set('camera' + camera + '_modified_at', null);
        }
      };

      xhr.send();
    },
    requestLiveImage: function requestLiveImage() {
      var _this = this;

      var camera = this.get('camera');
      var xhr = new XMLHttpRequest();
      xhr.open('POST', '/live_check', true);
      this.get('session').authorize('authorizer:devise', function (headerName, headerValue) {
        var headers = {};
        headers[headerName] = headerValue;
        xhr.setRequestHeader(headerName, headerValue);
      });
      xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      xhr.send('device_id=' + this.get('model.serial') + '&camera=' + camera);
    },
    runNextRequestLater: function runNextRequestLater() {
      this.timer = Ember.run.later(this, function () {
        var now = Date.now();
        this.set('now', now);
        var start = this.get('pollingStart'); //console.log("run next request after " + (now - start) + 'ms')

        if (now - start < 30000) {
          this.get('model').reload();
          this.requestLiveImage();
          this.fetchLiveImage();
          this.runNextRequestLater();
          this.set('remainingSecs', 30 - Math.round((now - start) / 1000));
        } else {
          this.set('requestRunning', false);
        }
      }, 1000);
    },
    init: function init() {
      this.set('pollingStart', 0);
      this.set('requestRunning', false);
      this.set('cameraSource1', null);
      this.set('cameraSource2', null);
      this.set('camera', 1);
    },
    request: function request() {
      this.set('pollingStart', Date.now());
      this.set('remainingSecs', 30);
      this.set('requestRunning', true);
      this.requestLiveImage();
      this.fetchLiveImage();
      this.runNextRequestLater();
    },
    actions: {
      close: function close() {
        this.init(), Ember.run.cancel(this.timer);
        history.back();
      },
      request: function request() {
        this.request();
      },
      shown: function shown() {
        this.init();

        if (this.get('model.cameras') == 1) {
          // preload on E340 devices
          this.requestLiveImage();
        }
      }
    }
  });

  _exports.default = _default;
});