define("vts-ember/templates/device", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JmaRMBZF",
    "block": "{\"symbols\":[],\"statements\":[[6,\"h2\"],[7],[0,\"Device \"],[6,\"small\"],[7],[1,[20,[\"model\",\"serial\"]],false],[8],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"model\",\"gps_available\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"form-group\"],[7],[0,\"\\n    \"],[1,[25,\"device-map\",null,[[\"device\"],[[20,[\"model\"]]]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[6,\"div\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"isEditing\"]]],null,{\"statements\":[[0,\"    \"],[12,\"devices/device-edit-fields\",[]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[12,\"devices/device_show_fields\",[]],[0,\"\\n\"]],\"parameters\":[]}],[8],[0,\"\\n\\n\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "vts-ember/templates/device.hbs"
    }
  });

  _exports.default = _default;
});