define("vts-ember/routes/trips", ["exports", "vts-ember/config/environment", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _environment, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    queryParams: {
      currentPage: {
        refreshModel: true
      },
      recordsPerPage: {
        refreshModel: true
      },
      sortWith: {
        refreshModel: true
      },
      order: {
        refreshModel: true
      },
      startAt: {
        refreshModel: true
      },
      endAt: {
        refreshModel: true
      }
    },

    /*
     * Get Trips to list for selected devices if available
     * else list all trips
     */
    project: Ember.inject.service(),
    model: function model(params) {
      var selectedIDs = this.controllerFor('devices').getSelectedDeviceIds();

      if (!(selectedIDs === undefined || selectedIDs === null)) {
        params = Object.assign({
          device: selectedIDs.slice(0, _environment.default.APP.URI_DEVICE_LIMIT)
        }, params);
      }

      if (this.modelFor('application')) {
        params.projectId = this.modelFor('application');
      } else {
        params.projectId = this.get('project.project.id');
      }

      return this.store.query('trip', params);
    },
    setupController: function setupController(controller, model) {
      controller.set('projectId', this.get('project.project.id'));

      this._super(controller, model);

      controller.set('recordsNo', model.get('meta').total);
    },
    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});