define("vts-ember/controllers/projects/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ProjectsConfigController = Ember.Controller.extend({
    application: Ember.inject.controller(),
    devices: Ember.inject.controller(),
    timezones: ['NZDT', 'NZT', 'AEDT', 'AEST', 'ACDT', 'ACST', 'AWST', 'MYT', 'GST', 'AST', 'SAST', 'MET', 'GMT', 'ATLST', 'EST', 'CST', 'MST', 'MST-Arizona', 'PST', 'CLT', 'HST', 'EET'],
    measurements: ['mph', 'kmh'],
    frameRates: ['12.5', '5', '1'],
    gpsRates: ['1', '5', '10', '60', '300'],
    highFilter: 0,
    project: null,
    startEditing: function startEditing() {
      this.stopEditing();
    },
    stopEditing: function stopEditing() {
      this.get('model').rollbackAttributes();
    },
    actions: {
      save: function save() {
        if (this.get('model.validClean')) {
          var _this = this;

          this.set('model.kmhHighFilter', Math.round(this.get('highFilter') * 1.60934));
          this.get('model').save().then(function () {
            window.alert('Configuration saved.');

            _this.transitionToRoute('project', _this.get('project'));
          });
        }
      },
      cancel: function cancel() {
        this.stopEditing();
        this.transitionToRoute('project', this.get('project'));
      }
    }
  });
  var _default = ProjectsConfigController;
  _exports.default = _default;
});