define("vts-ember/helpers/g-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (params) {
    var value = params[0],
        formatted = parseFloat(value, 10).toFixed(2);

    if (Ember.isEmpty(value) || isNaN(formatted)) {
      formatted = '';
    }

    return "".concat(formatted, " g");
  });

  _exports.default = _default;
});