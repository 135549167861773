define("vts-ember/instance-initializers/ember-simple-auth", ["exports", "ember-simple-auth/instance-initializers/setup-session-restoration"], function (_exports, _setupSessionRestoration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'ember-simple-auth',
    initialize: function initialize(instance) {
      (0, _setupSessionRestoration.default)(instance);
    }
  };
  _exports.default = _default;
});