define("vts-ember/controllers/users/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UsersNewController = Ember.Controller.extend({
    application: Ember.inject.controller(),
    currentUser: Ember.inject.service(),
    selectedProject: null,
    selectedDevice: null,
    rolesOptions: ['project admin', 'user'],
    projects: null,
    chooseProjects: true,
    devices: null,
    chooseDevices: true,
    isNew: true,
    isNotNew: false,
    ssoProviderOptions: [{
      label: 'internal',
      value: ''
    }, {
      label: 'Masternaut Connect',
      value: 'connect'
    }],
    assignProjects: function () {
      var _this = this;

      var selectedProject = this.get('selectedProject');

      if (selectedProject) {
        this.store.query('device', {
          projectId: selectedProject.get('projectId')
        }).then(function (devs) {
          _this.set('devices', devs);
        });
      }
    }.observes('selectedProject'),
    userRole: function () {
      if (this.get('role') === 'admin') {
        this.set('chooseProjects', false);
        this.set('chooseDevices', false);
      } else if (this.get('role') === 'project admin') {
        this.set('chooseProjects', true);
        this.set('chooseDevices', false);
      } else {
        this.set('chooseProjects', true);
        this.set('chooseDevices', true);
      }
    }.observes('role'),
    startEditing: function startEditing() {
      this.set('model', this.store.createRecord('user'));
    },
    stopEditing: function stopEditing() {
      var user = this.get('model');

      if (user) {
        user.rollbackAttributes();
      }
    },
    containsObject: function containsObject(obj, list) {
      var i;

      for (i = 0; i < list.length; i++) {
        if (list[i] === obj) {
          return true;
        }
      }

      return false;
    },
    addSucceeded: function addSucceeded(user) {
      this.transitionToRoute('users.user', user);
    },
    addFailed: function addFailed(response) {
      console.log("adding user failed:" + response);
      window.alert('Username already exists. Please choose a different one!');
    },
    actions: {
      addDevice: function addDevice() {
        var devices = this.get('model.devices');
        var device = this.get('selectedDevice');

        if (device) {
          devices.pushObject(device);
        }
      },
      updateSelectedDevice: function updateSelectedDevice(device) {
        this.set('selectedDevice', device);
      },
      updateSelectedDevices: function updateSelectedDevices(item) {
        this.set('model.devices', item);
      },
      updateSelectedProject: function updateSelectedProject(project) {
        this.set('selectedProject', project);
      },
      removeDevice: function removeDevice(device) {
        this.get('model.devices').removeObject(device);
      },
      save: function save() {
        var _this = this;

        if (this.get('model.validationError')) {
          window.alert('Please fill out the whole form.');
        } else {
          // check if exist alert
          var usrExist = this.store.peekRecord('user', this.get('model.email'));

          if (usrExist != null) {
            window.alert('Username already exists. Please choose a different one!');
          } else {
            var user = this.get('model');
            var project = this.get('selectedProject.projectId');

            if (project != null) {
              user.set('projectId', project);
            } else if (this.get('role') === 'admin') {
              user.set('projectId', null);
            } else {
              window.alert('No project selected.');
              return;
            }

            user.set('activated', true);
            this.get('model').save().then(function (user) {
              _this.addSucceeded(user);
            }).catch(function (response) {
              _this.addFailed(response);
            });
          }
        }
      },
      cancel: function cancel() {
        this.stopEditing();
        this.transitionToRoute('users.index');
      }
    }
  });
  var _default = UsersNewController;
  _exports.default = _default;
});