define("vts-ember/templates/project/config/action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3XlvTpIP",
    "block": "{\"symbols\":[],\"statements\":[[6,\"form\"],[9,\"class\",\"form-horizontal\"],[7],[0,\"\\n\\n  \"],[6,\"legend\"],[7],[0,\"Project configuration\"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"Roll out project configurations for all devices on project\\n    \"],[1,[20,[\"project\",\"id\"]],false],[0,\".\"],[8],[0,\"\\n  \"],[6,\"button\"],[9,\"class\",\"btn btn-default\"],[3,\"action\",[[19,0,[]],\"rollout\"]],[7],[0,\"Rollout\"],[8],[0,\"\\n\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "vts-ember/templates/project/config/action.hbs"
    }
  });

  _exports.default = _default;
});