define("vts-ember/components/video-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    map: null,
    gpsData: null,
    tripInitialized: false,
    markerArray: [],
    markerPoints: [],
    markStart: null,
    markLength: 1,

    /* Stack-based Douglas Peucker line simplification routine 
     returned is a reduced google.maps.LatLng array 
     After code by  Dr. Gary J. Robinson,
     Environmental Systems Science Centre,
     University of Reading, Reading, UK
    */
    didLoadTrip: function (view) {
      this.clearOverlay();
      var self = this;
      var videoLatLng = [];
      var ll = null;
      var path = [];
      var bounds = new google.maps.LatLngBounds();
      self.gpsData.forEach(function (gps) {
        ll = new google.maps.LatLng(gps.get('latitude'), gps.get('longitude'));
        ll.timeFormatted = gps.get('timeFormatted');
        ll.time = gps.get('time');
        path.push(ll);
        bounds.extend(ll);
      });
      var end,
          start = this.get('markStart');

      if (start != null) {
        end = new Date(start.getTime() + this.get('markLength') * 1000);
      }

      path.forEach(function (ll) {
        if (start && ll.time >= start && ll.time <= end) {
          self.markerPoints.push(self.createMarkerPoint(ll, 'orangered'));
        } else {
          self.markerPoints.push(self.createMarkerPoint(ll, 'black'));
        }
      });

      if (path.length > 0) {
        self.markerArray.push(self.createMarker(path[0], 'Start', 'https://maps.google.com/mapfiles/dd-start.png'));
        self.markerArray.push(self.createMarker(path[path.length - 1], 'Stop', 'https://maps.google.com/mapfiles/dd-end.png')); // self.get('map').fitBounds(bounds)

        self.set('tripInitialized', true);
        self.get('map').panTo(path[0]);
      }

      google.maps.event.trigger(this.map, 'resize');
    }.observes('gpsData'),
    createMarkerPoint: function createMarkerPoint(ll, color) {
      var map = this.get('map');

      var _this = this;

      var marker = new google.maps.Marker({
        position: ll,
        title: ll.timeFormatted,
        icon: {
          strokeColor: color,
          path: google.maps.SymbolPath.CIRCLE,
          scale: 2
        },
        map: map
      });
      marker.time = ll.time;
      google.maps.event.addListener(marker, 'click', function () {
        _this.sendAction('action', ll.time);
      });
      return marker;
    },
    createMarker: function createMarker(latlng, title, icon) {
      var contentString = title;
      var marker = new google.maps.Marker({
        position: latlng,
        map: this.get('map'),
        icon: icon,
        zIndex: Math.round(latlng.lat() * -100000) << 5,
        title: title
      });
      var infowindow = new google.maps.InfoWindow({
        size: new google.maps.Size(150, 50)
      });
      google.maps.event.addListener(marker, 'click', function () {
        infowindow.setContent(contentString);
        infowindow.open(this.get('map'), marker);
      });
      return marker;
    },
    deleteMarkers: function deleteMarkers() {
      this.markerArray.forEach(function (marker) {
        marker.setMap(null);
      });
      this.markerArray = [];
    },
    clearOverlay: function clearOverlay() {
      this.deleteMarkers();
      this.markerPoints.forEach(function (marker) {
        marker.setMap(null);
      });
      this.markerPoints = [];
    },
    refreshTrip: function () {
      var end,
          start = this.get('markStart');
      var position = null;
      var url, origin, anchor;
      var map = this.get('map');

      if (start != null) {
        end = new Date(start.getTime() + this.get('markLength') * 1000);
      }

      var color = 'black';
      this.markerPoints.forEach(function (marker) {
        url = origin = anchor = null;

        if (start && marker.time >= start && marker.time <= end) {
          color = 'orangered';

          if (position == null) {
            url = 'https://maps.google.com/mapfiles/arrow.png', origin = new google.maps.Point(0, 0), anchor = new google.maps.Point(12, 32);
            position = marker.position;
          }

          map.panTo(position);
        } else {
          color = 'black';
        }

        if (url != null) {
          marker.setIcon({
            url: url,
            origin: origin,
            anchor: anchor
          });
        } else {
          marker.setIcon({
            strokeColor: color,
            path: google.maps.SymbolPath.CIRCLE,
            scale: 2
          });
        }
      });
    }.observes('markStart', 'markLength'),
    didInsertElement: function didInsertElement() {
      var mapOptions = {
        zoom: 17,
        maxZoom: 20,
        minZoom: 3,
        center: new google.maps.LatLng(75.271567, 10.54617628220852),
        mapTypeControl: false,
        panControl: true,
        zoomControl: true,
        fullscreenControl: false,
        scaleControl: false,
        streetViewControl: false,
        navigationControl: false,
        draggable: true,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      };
      this.set('map', new google.maps.Map(this.$().get(0), mapOptions));
      this.map.initialZoom = true;
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this2 = this;

      if (!this.get('tripInitialized')) {
        Promise.resolve(this.get('gpsData')).then(function (x) {
          _this2.set('gpsData', x);
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (this.map) {
        var node = this.map.getDiv();

        if (node) {
          while (node.hasChildNodes()) {
            node.removeChild(node.firstChild);
          }
        }

        delete this.map;
        this.map = null;
      }
    }
  });

  _exports.default = _default;
});