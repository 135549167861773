define("vts-ember/models/config-request", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ConfigRequest = _emberData.default.Model.extend({
    device: _emberData.default.belongsTo('device', {
      async: true
    }),
    deviceConfig: _emberData.default.belongsTo('deviceConfig', {
      async: true
    }),
    // not needed:
    configId: _emberData.default.attr('string'),
    // functionalies attribues need on device
    frameRate: _emberData.default.attr('string'),
    xgforceLevel1: _emberData.default.attr('string'),
    ygforceLevel1: _emberData.default.attr('string'),
    zgforceLevel1: _emberData.default.attr('string'),
    xgforceLevel2: _emberData.default.attr('string'),
    ygforceLevel2: _emberData.default.attr('string'),
    zgforceLevel2: _emberData.default.attr('string'),
    xgforceLevel3: _emberData.default.attr('string'),
    ygforceLevel3: _emberData.default.attr('string'),
    zgforceLevel3: _emberData.default.attr('string'),
    preAlarm: _emberData.default.attr('number'),
    // no of seconds before shock event to record
    postAlarm: _emberData.default.attr('number'),
    // no of seconds after shock event to record
    inputEnabled: _emberData.default.attr('boolean'),
    irecPreduration: _emberData.default.attr('number'),
    irecPostduration: _emberData.default.attr('number'),
    gpsTransferRate: _emberData.default.attr('string'),
    // funtionalities to enable/disable on device
    contRecEnabled: _emberData.default.attr('boolean'),
    // continuous recording (SD card recording)
    // camera 2 enable/disable continuous recording
    c2contRecEnabled: _emberData.default.attr('boolean'),
    eventBtnEnabled: _emberData.default.attr('boolean'),
    // button triggers video event & upload
    retriggerEnabled: _emberData.default.attr('boolean'),
    // retrigger postAlarm recording
    pingEnabled: _emberData.default.attr('boolean'),
    eventPartitionSize: _emberData.default.attr('number'),
    incidentUploadEnabled: _emberData.default.attr('boolean'),
    normalizeFilter: _emberData.default.attr('boolean'),
    kmhHighFilter: _emberData.default.attr('number'),
    shutdownDelay: _emberData.default.attr('number'),
    audioRecording: _emberData.default.attr('boolean'),
    // general settings on device
    timezone: _emberData.default.attr('string'),
    measurement: _emberData.default.attr('string'),
    // mph or kmh
    validGforceMsg: function validGforceMsg(value) {
      var msg = '';
      var pattern = /^[+]?[0-2]*\.?[0-9]+$/i;

      if (value.match(pattern) == null) {
        msg = 'Gforce must be a number';
      }

      if (value != null) {
        if (parseFloat(value) < 0.45 || parseFloat(value) > 2.0) {
          msg = 'Gforce must be a value between 0.45 and 2.0';
        }
      }

      return msg;
    },
    validPreAlarm: function () {
      var pre_alarm = parseInt(this.get('preAlarm'));
      var post_alarm = parseInt(this.get('postAlarm'));
      var msg = '';

      if (pre_alarm > post_alarm) {
        msg = 'Pre-Alarm Seconds must be less that Post-Alarm Seconds';
      }

      return msg;
    }.property('preAlarm', 'postAlarm'),
    validPostAlarm: function () {
      var pre_alarm = parseInt(this.get('preAlarm'));
      var post_alarm = parseInt(this.get('postAlarm'));
      var msg = '';

      if (post_alarm < pre_alarm) {
        msg = 'Post-Alarm Seconds must be more that Pre-Alarm Seconds';
      }

      return msg;
    }.property('preAlarm', 'postAlarm'),
    validIrecPostAlarm: function () {
      var pre_duration = parseInt(this.get('irecPreduration'));
      var post_duration = parseInt(this.get('irecPostduration'));
      var msg = '';

      if (post_duration < pre_duration) {
        msg = 'Post-Alarm Seconds must be more that Pre-Alarm Seconds';
      }

      return msg;
    }.property('irecPreduration', 'irecPostduration'),
    validIrecPreAlarm: function () {
      var pre_duration = parseInt(this.get('irecPreduration'));
      var post_duration = parseInt(this.get('irecPostduration'));
      var msg = '';

      if (post_duration < pre_duration) {
        msg = 'Pre-Alarm Seconds must be less that Post-Alarm Seconds';
      }

      return msg;
    }.property('irecPreduration', 'irecPostduration'),
    validLow: function () {
      var x = this.get('xgforceLevel1');
      var y = this.get('ygforceLevel1');
      var msg = '';

      if (x > this.get('xgforceLevel2') || x > this.get('xgforceLevel3') || y > this.get('ygforceLevel2') || y > this.get('ygforceLevel3')) {
        msg = 'Gforce low level must be less than the medium and high levels!';
      }

      return msg;
    }.property('xgforceLevel1', 'xgforceLevel2', 'xgforceLevel3', 'ygforceLevel1', 'ygforceLevel2', 'ygforceLevel3'),
    validMedium: function () {
      var x = this.get('xgforceLevel2');
      var y = this.get('ygforceLevel2');
      var msg = '';

      if (x < this.get('xgforceLevel1') || x > this.get('xgforceLevel3') || y < this.get('ygforceLevel1') || y > this.get('ygforceLevel3')) {
        msg = 'Gforce medium level must be a value between the low and high levels!';
      }

      return msg;
    }.property('xgforceLevel1', 'xgforceLevel2', 'xgforceLevel3', 'ygforceLevel1', 'ygforceLevel2', 'ygforceLevel3'),
    validHigh: function () {
      var x = this.get('xgforceLevel3');
      var y = this.get('ygforceLevel3');
      var msg = '';

      if (x < this.get('xgforceLevel1') || x < this.get('xgforceLevel2') || y < this.get('ygforceLevel1') || y < this.get('ygforceLevel2')) {
        msg = 'Gforce high level must be greater than the medium and low levels!';
      }

      return msg;
    }.property('xgforceLevel1', 'xgforceLevel2', 'xgforceLevel3', 'ygforceLevel1', 'ygforceLevel2', 'ygforceLevel3'),
    validClean: function () {
      if (this.get('validLow') === '' && this.get('validMedium') === '' && this.get('validHigh') === '' && this.get('validPreAlarm') === '' && this.get('validPostAlarm') === '' && this.get('validIrecPreAlarm') === '' && this.get('validIrecPostAlarm') === '') {
        return true;
      } else {
        return false;
      }
    }.property('validLow', 'validMedium', 'validHigh', 'validPreAlarm', 'validPostAlarm', 'validIrecPreAlarm', 'validIrecPostAlarm')
  });

  var _default = ConfigRequest;
  _exports.default = _default;
});