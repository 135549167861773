define("vts-ember/controllers/video-request/trip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      openPreviewRequest: function openPreviewRequest() {
        this.transitionToRoute('video-request.trip.preview');
      },
      openVideoRequest: function openVideoRequest() {
        this.transitionToRoute('video-request.trip.request');
      }
    }
  });

  _exports.default = _default;
});