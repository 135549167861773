define("vts-ember/templates/project", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BVuWalJG",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"panel panel-default\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"panel-heading\"],[7],[0,\" Project properties \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"panel-body\"],[7],[0,\"\\n\\n    \"],[6,\"ul\"],[9,\"class\",\"nav nav-pills\"],[7],[0,\"\\n\\n\"],[4,\"link-to\",[\"project.index\"],[[\"tagName\",\"href\"],[\"li\",false]],{\"statements\":[[4,\"link-to\",[\"project.index\"],null,{\"statements\":[[0,\"          General\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"if\",[[20,[\"application\",\"canConfigDevice\"]]],null,{\"statements\":[[4,\"link-to\",[\"project.config\"],[[\"tagName\",\"href\"],[\"li\",false]],{\"statements\":[[4,\"link-to\",[\"project.config.v0\"],null,{\"statements\":[[0,\"            Device configuration\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n    \"],[6,\"br\"],[7],[8],[0,\"\\n\\n    \"],[1,[18,\"outlet\"],false],[0,\"\\n\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "vts-ember/templates/project.hbs"
    }
  });

  _exports.default = _default;
});