define("vts-ember/models/trip", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Trip = _emberData.default.Model.extend({
    start: _emberData.default.attr('date'),
    startFormatted: function () {
      if (this.get('start') != undefined) {
        return (0, _moment.default)(this.get('start')).format('yyyy-MM-DD H:mm:ss');
      }

      return null;
    }.property('start'),
    stop: _emberData.default.attr('date'),
    stopFormatted: function () {
      if (this.get('stop') != undefined) {
        return (0, _moment.default)(this.get('stop')).format('yyyy-MM-DD H:mm:ss');
      }

      return null;
    }.property('stop'),
    duration: function () {
      if (this.get('start') != undefined && this.get('stop') != undefined) {
        var msSecond = 1000;
        var msMinute = 60 * 1000;
        var msHour = 60 * 60 * 1000;
        var msDay = 60 * 60 * 24 * 1000;
        var sub = Math.abs(this.get('stop') - this.get('start'));
        var duration = '';

        if (Math.floor(sub / msDay) == 0) {
          if (Math.floor(sub % msDay / msHour) == 0) {
            if (Math.floor(sub % msHour / msMinute) == 0) {
              duration += Math.floor(sub % msMinute / msSecond) + ' sec';
            } else {
              duration += Math.floor(sub % msHour / msMinute) + ' min ' + Math.floor(sub % msMinute / msSecond) + ' sec';
            }
          } else {
            duration += Math.floor(sub % msDay / msHour) + ' hrs ';

            if (Math.floor(sub % msHour / msMinute) == 0) {
              duration += Math.floor(sub % msMinute / msSecond) + ' sec';
            } else {
              duration += Math.floor(sub % msHour / msMinute) + ' min ' + Math.floor(sub % msMinute / msSecond) + ' sec';
            }
          }
        } else {
          duration = Math.floor(sub / msDay) + ' days ';

          if (Math.floor(sub % msDay / msHour) == 0) {
            if (Math.floor(sub % msHour / msMinute) == 0) {
              duration += Math.floor(sub % msMinute / msSecond) + ' sec';
            } else {
              duration += Math.floor(sub % msHour / msMinute) + ' min ' + Math.floor(sub % msMinute / msSecond) + ' sec';
            }
          } else {
            duration += Math.floor(sub % msDay / msHour) + ' hrs ';

            if (Math.floor(sub % msHour / msMinute) == 0) {
              duration += Math.floor(sub % msMinute / msSecond) + ' sec';
            } else {
              duration += Math.floor(sub % msHour / msMinute) + ' min ' + Math.floor(sub % msMinute / msSecond) + ' sec';
            }
          }
        }

        return duration;
      } else {
        return null;
      } // duration = (this.get('stop') - this.get('start'));

    }.property('start', 'stop'),
    shutdownDelay: _emberData.default.attr('number'),
    shutdownDelayFormatted: function () {
      var delay = this.get('shutdownDelay');
      var result = '';

      if (delay >= 0) {
        var minutes = Math.floor(delay / 60);
        var seconds = Math.floor(delay % 60);

        if (minutes > 0) {
          result += minutes + ' min ';
        }

        if (seconds > 0 || minutes == 0) {
          result += seconds + ' sec';
        }
      }

      return result;
    }.property('shutdownDelay'),
    mileage: _emberData.default.attr('string'),
    distance: function () {
      var unit = this.get('currentUser.setting.distanceUnit');
      var dist = this.get('mileage');

      if (unit == 'km') {
        dist = dist / 1000.0;
      } else {
        dist = dist / 1609.34;
      }

      var res = Math.floor(dist * 10000) / 10000;
      return res.toFixed(4);
    }.property('mileage'),
    device: _emberData.default.belongsTo('device', {
      async: true
    }),
    state: _emberData.default.attr('string'),
    stateBtn: function () {
      var btnClass = 'btn btn-sm disabled ';
      var state = this.get('state');

      if (state === 'open') {
        btnClass += 'btn-success';
      } else if (state === 'closed' || state === '') {
        btnClass += 'btn-primary';
      } else {
        btnClass += 'btn-idle';
      }

      return btnClass;
    }.property('state'),
    stateTooltip: function () {
      var state = this.get('state');
      var toolTip = '';

      switch (state) {
        case 'open':
          toolTip = 'Running';
          break;

        case 'closed':
          toolTip = 'Complete';
          break;

        case 'incomplete':
          toolTip = 'Incomplete';
          break;
      }

      return toolTip;
    }.property('state'),
    onlineTooltip: function () {
      var state = this.get('state'); // console.log('state.....', state);

      if (state === 'offline' || state === 'disconnected') {
        if (this.get('lastConnected') == null || this.get('lastConnected') == 'undefined') {
          return state;
        } else {
          return state + '\n' + this.get('lastConnected');
        }
      } else if (state === 'online') {
        return state;
      } else {
        return 'idle';
      }
    }.property('state', 'lastConnected'),
    onlineBtn: function () {
      var btnClass = 'btn btn-sm disabled ';

      if (this.get('state') === 'online') {
        btnClass += 'btn-success';
      } else {
        btnClass += 'btn-primary';
      }

      return btnClass;
    }.property('eventState')
  });

  var _default = Trip;
  _exports.default = _default;
});