define("vts-ember/helpers/pollster", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Pollster = Ember.Object.extend({
    _this: null,
    interval: function () {
      return 60000; // Time between polls (in ms)
    }.property().readOnly(),
    // Schedules the function `f` to be executed every `interval` time.
    schedule: function schedule(f) {
      return Ember.run.later(this, function () {
        f.apply(this);
        this.set('timer', this.schedule(f));
      }, this.get('interval'));
    },
    // Stops the pollster
    stop: function stop() {
      Ember.run.cancel(this.get('timer'));
    },
    // Starts the pollster, i.e. executes the `onPoll` function every interval.
    start: function start() {
      this.set('timer', this.schedule(this.get('onPoll')));
    },
    onPoll: function onPoll() {// Issue JSON request and add data to the store
    }
  });
  var _default = Pollster;
  _exports.default = _default;
});