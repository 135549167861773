define("vts-ember/templates/video-request", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oUmf8ImL",
    "block": "{\"symbols\":[],\"statements\":[[6,\"h2\"],[7],[0,\"Video requests\"],[8],[0,\"\\n\"],[6,\"ul\"],[9,\"class\",\"nav nav-tabs\"],[7],[0,\"\\n\"],[4,\"link-to\",[\"video-request.new\"],[[\"tagName\",\"href\"],[\"li\",false]],{\"statements\":[[4,\"link-to\",[\"video-request.new\"],[[\"bubbles\"],[false]],{\"statements\":[[0,\"      New request\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"link-to\",[\"video-request.previews\"],[[\"tagName\",\"href\"],[\"li\",false]],{\"statements\":[[4,\"link-to\",[\"video-request.previews\"],[[\"bubbles\"],[false]],{\"statements\":[[0,\"      Preview downloads\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"link-to\",[\"video-request.active\"],[[\"tagName\",\"href\"],[\"li\",false]],{\"statements\":[[4,\"link-to\",[\"video-request.active\"],[[\"bubbles\"],[false]],{\"statements\":[[0,\"      Video downloads\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"tab-content\"],[7],[0,\"\\n  \"],[1,[18,\"outlet\"],false],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "vts-ember/templates/video-request.hbs"
    }
  });

  _exports.default = _default;
});