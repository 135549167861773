define("vts-ember/controllers/connection-lost", ["exports", "vts-ember/controllers/devices"], function (_exports, _devices) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var DisconnectedMainController = _devices.default.extend({
    application: Ember.inject.controller(),
    queryParams: ['search', 'sortWith', 'order'],
    sortBy: 'lastConnectedFormatted',
    sortAscending: false,
    filterParams: ['serial', 'fwVersion', 'fwBuild', 'lastConnectedFormatted', 'stateTooltip'],
    sortByLastConnectedFormatted: function () {
      return this.get('sortBy') == 'lastConnectedFormatted';
    }.property('sortBy')
  });

  var _default = DisconnectedMainController;
  _exports.default = _default;
});