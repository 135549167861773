define("vts-ember/controllers/config_requests/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ConfigRequestsNewController = Ember.Controller.extend({
    application: Ember.inject.controller(),
    devices: Ember.inject.controller(),
    deviceConfig: null,
    timezones: ['NZDT', 'NZT', 'AEDT', 'AEST', 'ACDT', 'ACST', 'AWST', 'MYT', 'GST', 'AST', 'SAST', 'MET', 'GMT', 'ATLST', 'EST', 'CST', 'MST', 'MST-Arizona', 'PST', 'CLT', 'HST', 'EET'],
    measurements: ['mph', 'kmh'],
    frameRates: ['12.5', '5', '1'],
    gpsRates: ['1', '5', '10', '60', '300'],
    startEditing: function startEditing() {
      this.set('model', this.store.createRecord('configRequest'));
      this.set('model.deviceConfig', this.get('deviceConfig'));
    },
    stopEditing: function stopEditing() {
      var model = this.get('model');

      if (model) {
        model.rollbackAttributes();
      }
    },
    save: function save() {
      this.set('model.configId', this.get('device.id'));
      this.get('model').save().then(function () {
        this.transitionToRoute('devices.index');
      });
    },
    cancel: function cancel() {
      this.stopEditing();
      this.transitionToRoute('configs.index');
    }
  });
  var _default = ConfigRequestsNewController;
  _exports.default = _default;
});