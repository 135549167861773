define("vts-ember/templates/video-request/confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PzogNbbx",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"title\",\"close\",\"ok\"],[\"Video request\",\"removeModal\",\"save\"]],{\"statements\":[[0,\"  \"],[6,\"p\"],[7],[0,\"\\n    In order to submit a Video Request, the device must be active. A Video\\n    Request for an active device will be submitted, and begin uploading\\n    immediately. A Video Request for an inactive device will be submitted,\\n    and queued for upload immediately upon the device powering on.\\n  \"],[8],[0,\"\\n  \"],[6,\"p\"],[7],[0,\"\\n    For an inactive device that requires an immediate Video Request, please\\n    turn on the vehicle’s ignition.\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "vts-ember/templates/video-request/confirm.hbs"
    }
  });

  _exports.default = _default;
});