define("vts-ember/components/range-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RangeInputComponent = Ember.Component.extend({
    type: 'range',
    tagName: 'input',
    classNames: ['x-range-input'],
    attributeBindings: ['type', 'value', 'min', 'max', 'step', 'list', 'disabled'],
    value: 0,
    min: 0,
    max: 100,
    step: 1,
    input: function input(event) {
      this.set('value', this.get('element.value'));
    },
    copyValue: Ember.observer('value', function () {
      this.set('element.value', this.get('value'));
    })
  });
  var _default = RangeInputComponent;
  _exports.default = _default;
});