define("vts-ember/routes/project/config/base", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      var id = null;
      var suffix = this.get('suffix');

      if (typeof params.project_id === 'undefined') {
        if (this.modelFor('project')) {
          id = this.modelFor('project').get('id');
        } else {// console.log('(model ' + suffix + ') Cannot get ID')
        }
      } else {
        id = params.project_id;
      }

      return this.store.findRecord('projectConfig', id + '_' + suffix);
    },
    setupController: function setupController(controller, model) {
      controller.set('highFilter', model.get('kmhHighFilter') / 1.60934);

      this._super(controller, model);

      var project = this.modelFor('project');
      controller.set('project', project);

      if (project == null) {
        console.log('(setupController ' + this.get('suffix') + ') project not set');
      }

      controller.startEditing();
    },
    deactivate: function deactivate() {
      this.controller.stopEditing();
    }
  });

  _exports.default = _default;
});