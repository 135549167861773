define("vts-ember/components/simple-select", ["exports", "vts-ember/templates/components/simple-select"], function (_exports, _simpleSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _simpleSelect.default,
    classNames: ['native-simple-select'],
    classNameBindings: ['isFocused'],
    actions: {
      focusIn: function focusIn() {
        this.set('isFocused', true);
      },
      focusOut: function focusOut() {
        this.set('isFocused', false);
      },
      updateDisplayLabel: function updateDisplayLabel() {
        this.set('displayLabel', this.$('option:selected').text());
      }
    }
  });

  _exports.default = _default;
});