define("vts-ember/models/video-state-complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VIDEO_STATE_COMPLETE = 'complete';
  var _default = VIDEO_STATE_COMPLETE;
  _exports.default = _default;
});