define("vts-ember/templates/components/video-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mCXimw4l",
    "block": "{\"symbols\":[],\"statements\":[[6,\"video\"],[9,\"id\",\"player\"],[9,\"debug\",\"true\"],[10,\"src\",[18,\"src\"],null],[9,\"controls\",\"\"],[9,\"data-plyr-config\",\"{ \\\"speed\\\": { \\\"selected\\\": 10, \\\"options\\\": [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 10] }}\"],[7],[0,\" \"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "vts-ember/templates/components/video-player.hbs"
    }
  });

  _exports.default = _default;
});