define("vts-ember/components/from-to-picker", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    start: null,
    end: null,
    maxDate: Date.now() + 1000 * 60 * 60 * 24,
    actions: {
      startChanged: function startChanged(start) {
        if (start) {
          this.set('start', (0, _moment.default)(start[0]).toISOString());
        }
      },
      endChanged: function endChanged(end) {
        if (end) {
          this.set('end', (0, _moment.default)(end[0]).toISOString());
        }
      },
      filter: function filter() {
        var start = this.get('start');

        if (start) {
          this.set('startAt', start);
        }

        var end = this.get('end');

        if (end) {
          this.set('endAt', end);
        }
      },
      clear_filter: function clear_filter() {
        this.set('startAt', '');
        this.set('endAt', '');
      }
    }
  });

  _exports.default = _default;
});