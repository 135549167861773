define("vts-ember/controllers/video-request/trips", ["exports", "vts-ember/controllers/tables"], function (_exports, _tables) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var VideoRequestTripsController = _tables.default.extend({
    currentUser: Ember.inject.service(),
    devices: Ember.inject.controller(),
    application: Ember.inject.controller(),
    queryParams: ['currentPage', 'recordsPerPage', 'startAt', 'endAt'],
    sortBy: 'start',
    sortAscending: false,
    recordsPerPage: 100,
    sortWith: 'start',
    order: 'desc',
    startAt: null,
    endAt: null,
    orderIconStart: '',
    orderIconDevice: '',
    icon: 'icon-chevron-down',
    unit: 'km',
    startDate: Ember.computed('startAt', function () {
      return new Date(this.get('startAt') || 'now');
    }),
    endDate: Ember.computed('endAt', function () {
      return new Date(this.get('endAt') || 'now');
    }),

    /* NB: This does not work on a page reload due to devices on trips are loaded asynchronously and footageStart not being available.
     *     Decided not to fix as this does not seem to be super relevant.
     */
    filteredContent: function () {
      var model = this.get('model');

      if (model) {
        return model.filter(function (item, index) {
          var footageStart = item.get('device.footageStart');

          if (footageStart < item.get('stop')) {
            return item;
          }
        });
      } else {
        return null;
      }
    }.property('model.[]', 'device'),
    actions: {
      sortChange: function sortChange(item) {
        if (item == this.sortWith) {
          if (this.order === 'desc') {
            this.set('order', 'asc');
            this.set('icon', 'icon-chevron-up');
          } else {
            this.set('order', 'desc');
            this.set('icon', 'icon-chevron-down');
          }
        }

        this.set('sortWith', item);
      }
    }
  });

  var _default = VideoRequestTripsController;
  _exports.default = _default;
});