define("vts-ember/utils/select-box/scroll-into-view", ["exports", "@zestia/ember-select-box/utils/select-box/scroll-into-view"], function (_exports, _scrollIntoView) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _scrollIntoView.default;
    }
  });
});