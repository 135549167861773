define("vts-ember/routes/settings", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),
    model: function model() {
      var email = this.get('currentUser.user.email');

      if (email) {
        return this.store.findRecord('setting', email);
      } else {
        this.transitionTo('index');
      }
    }
  });

  _exports.default = _default;
});