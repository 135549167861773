define("vts-ember/components/data-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DataChartComponent = Ember.Component.extend({
    accelData: null,
    marked: null,
    classNames: ['data-chart'],
    title: null,
    seriesColor: null,
    didInsertElement: function didInsertElement() {
      this._super();

      var id = this.$().attr('id');
      var accelData = this.get('accelData');
      var marked = this.get('marked');
      var timeseries;

      if (marked != null) {
        timeseries = [accelData, marked];
      } else {
        timeseries = [accelData];
      }

      $.jqplot(id, timeseries, {
        title: this.get('title'),
        seriesColors: [this.get('seriesColor'), 'rgb(255, 16, 59)'],
        grid: {
          background: 'rgba(255,255,255,0.0)',
          drawBorder: false,
          shadow: false,
          gridLineColor: '#666666',
          gridLineWidth: 2
        },
        axes: {
          xaxis: {
            renderer: $.jqplot.DateAxisRenderer
          },
          yaxis: {
            // autoscale: true,
            min: -2.0,
            max: 2.0,
            label: 'g',
            labelRenderer: $.jqplot.CanvasAxisLabelRenderer,
            labelOptions: {
              fontFamily: 'Georgia, Serif',
              fontSize: '12pt'
            }
          }
        },
        highlighter: {
          show: true,
          sizeAdjust: 7.5
        },
        cursor: {
          show: true,
          zoom: true,
          showTooltip: false
        }
      });
    }
  });
  var _default = DataChartComponent;
  _exports.default = _default;
});