define("vts-ember/controllers/videos", ["exports", "vts-ember/controllers/tables", "moment"], function (_exports, _tables, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var VideosController = _tables.default.extend({
    application: Ember.inject.controller(),
    queryParams: ['currentPage', 'recordsPerPage', 'sortWith', 'order', 'startAt', 'endAt'],
    sortBy: 'start',
    sortAscending: false,
    sortWith: 'start',
    order: 'desc',
    startAt: null,
    endAt: null,
    startDate: Ember.computed('startAt', function () {
      return new Date(this.get('startAt') || Date.now() - 1000 * 60 * 60 * 24);
    }),
    endDate: Ember.computed('endAt', function () {
      return new Date(this.get('endAt') || Date.now());
    }),
    sortById: function () {
      return this.get('sortWith') == 'id';
    }.property('sortWith'),
    sortByStart: function () {
      return this.get('sortWith') == 'start';
    }.property('sortWith'),
    actions: {
      sortChange: function sortChange(item) {
        if (item == this.sortWith) {
          if (this.order === 'desc') {
            this.set('order', 'asc');
            this.set('sortAscending', true);
          } else {
            this.set('order', 'desc');
            this.set('sortAscending', false);
          }
        }

        this.set('sortWith', item);
      },
      startChanged: function startChanged(start) {
        if (start) {
          this.set('start', (0, _moment.default)(start[0]).toISOString());
        }
      },
      endChanged: function endChanged(end) {
        if (end) {
          this.set('end', (0, _moment.default)(end[0]).toISOString());
        }
      },
      filter: function filter() {
        this.set("startAt", this.get("start"));
        this.set("endAt", this.get("end"));
      }
    }
  });

  var _default = VideosController;
  _exports.default = _default;
});