define("vts-ember/templates/project/config/v4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ADdCwnPZ",
    "block": "{\"symbols\":[],\"statements\":[[12,\"project/config/config_edit_fields\",[]],[0,\"\\n\\n\"],[6,\"div\"],[9,\"class\",\"form-group\"],[7],[0,\"\\n  \"],[6,\"p\"],[9,\"class\",\"nav pull-right\"],[7],[0,\"\\n    \"],[6,\"button\"],[9,\"class\",\"btn btn-default\"],[3,\"action\",[[19,0,[]],\"cancel\"]],[7],[0,\"Cancel\"],[8],[0,\"\\n    \"],[6,\"button\"],[9,\"class\",\"btn btn-primary\"],[3,\"action\",[[19,0,[]],\"save\"]],[7],[0,\"Save\"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "vts-ember/templates/project/config/v4.hbs"
    }
  });

  _exports.default = _default;
});