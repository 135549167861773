define("vts-ember/routes/connection-lost", ["exports", "vts-ember/routes/devices"], function (_exports, _devices) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _devices.default.extend({
    setupController: function setupController(controller, model) {
      var length = 0;
      var modelArr = model.toArray();
      var recs = [];

      for (var i = 0; i < model.get('length'); i++) {
        if (modelArr[i].get('isDisconnected')) {
          recs.push(modelArr[i]);
          length++;
        }
      }

      controller.set('model', recs);
      controller.set('currentPage', 1);
      controller.set('recordsNo', length);
    }
  });

  _exports.default = _default;
});