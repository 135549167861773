define("vts-ember/helpers/select-box-class", ["exports", "@zestia/ember-select-box/helpers/select-box-class"], function (_exports, _selectBoxClass) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _selectBoxClass.default;
    }
  });
  Object.defineProperty(_exports, "selectBoxClass", {
    enumerable: true,
    get: function get() {
      return _selectBoxClass.selectBoxClass;
    }
  });
});