define("vts-ember/models/video", ["exports", "ember-data", "vts-ember/models/video-state-incomplete", "vts-ember/models/video-state-complete", "vts-ember/models/video-state-requested", "vts-ember/models/video-state-available", "moment"], function (_exports, _emberData, _videoStateIncomplete, _videoStateComplete, _videoStateRequested, _videoStateAvailable, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Video = _emberData.default.Model.extend({
    start: _emberData.default.attr('date'),
    startFormatted: function () {
      if (this.get('start') != undefined) {
        return (0, _moment.default)(this.get('start')).format('yyyy-MM-DD H:mm:ss');
      }

      return null;
    }.property('start'),
    startFormattedFilename: function () {
      if (this.get('start') != undefined) {
        return (0, _moment.default)(this.get('start')).format('YYMMDD-HHmmss');
      }

      return null;
    }.property('start'),
    state: _emberData.default.attr('string'),
    stop: _emberData.default.attr('date'),
    alarmType: _emberData.default.attr('string'),
    duration: function () {
      if (this.get('start') != undefined && this.get('stop') != undefined) {
        var msSecond = 1000;
        var msMinute = 60 * 1000;
        var msHour = 60 * 60 * 1000; // msDay = 60*60*24*1000;

        var sub = Math.abs(this.get('stop') - this.get('start'));
        var duration = '';

        if (sub != 0) {
          if (Math.floor(sub % msHour / msMinute) == 0) {
            duration += Math.floor(sub % msMinute / msSecond) + ' sec';
          } else {
            duration += Math.floor(sub % msHour / msMinute) + ' min ' + Math.floor(sub % msMinute / msSecond) + ' sec';
          }
        } else {
          duration = 'tbd';
        } // duration = (this.get('stop') - this.get('start'));


        return duration;
      } else {
        return null;
      }
    }.property('start', 'stop'),
    frame_count: _emberData.default.attr('number'),
    loadedFrameCount: _emberData.default.attr('number'),
    device: _emberData.default.belongsTo('device', {
      async: true
    }),
    camera: _emberData.default.attr('number'),
    videoFormat: _emberData.default.attr('string'),
    downloadEnabled: _emberData.default.attr('boolean'),
    gpsData: _emberData.default.hasMany('gpsDatum'),
    requestToken: _emberData.default.attr('string'),
    link: function () {
      var extension = this.get('videoFormat') || 'mov';

      if (this.get('start') != undefined) {
        var uri = "/videos/".concat(this.get('id'), ".").concat(extension);
        return encodeURI(uri);
      }

      return null;
    }.property('start', 'videoFormat', 'requestToken'),
    linkWithToken: function () {
      var extension = this.get('videoFormat') || 'mov';

      if (this.get('start') != undefined && this.get('requestToken')) {
        var uri = "https://vts.surecam.com/api/v1/videos/".concat(this.get('id'), ".").concat(extension, "?token=").concat(this.get('requestToken'));
        return encodeURI(uri);
      }

      return null;
    }.property('start', 'videoFormat', 'requestToken'),
    mp4Link: function () {
      if (this.get('start') != undefined && this.get('requestToken')) {
        var uri = "https://vts.surecam.com/api/v1/videos/".concat(this.get('id'), ".mp4?token=").concat(this.get('requestToken'));
        return encodeURI(uri);
      }

      return null;
    }.property('start', 'id', 'requestToken'),
    loadProgress: function () {
      var frame_count = this.get('frame_count') || 0;
      var loadedFrameCount = this.get('loadedFrameCount') || 0;
      return frame_count == 0 ? 0 : loadedFrameCount * 100 / frame_count;
    }.property('frame_count', 'loadedFrameCount'),
    styleProgress: function () {
      var progress = this.get('loadProgress') || 0;
      return "width: ".concat(progress, "%;");
    }.property('loadProgress'),
    isComplete: function () {
      var state = this.get('state') || _videoStateIncomplete.default;

      return state == _videoStateComplete.default;
    }.property('state'),
    isIncomplete: function () {
      return this.get('state') == _videoStateIncomplete.default || this.get('state') == _videoStateRequested.default;
    }.property('state'),

    /* a bit redundant, needs cleanup */
    notComplete: function () {
      var state = this.get('state') || _videoStateIncomplete.default;

      return state != _videoStateComplete.default;
    }.property('state'),
    isDownloadable: function () {
      var state = this.get('state');
      var cameras = this.get('device').get('cameras');
      var permitted = cameras != 2 || this.get('downloadEnabled') || false;
      var sub = Math.abs(this.get('stop') - this.get('start'));
      return permitted && state != _videoStateAvailable.default && (this.get('loadedFrameCount') || 0) > 0 && !(this.get('isIncomplete') && sub > 60000);
    }.property('state', 'downloadEnabled', 'isIncomplete', 'loadedFrameCount'),
    isAvailable: function () {
      var state = this.get('state');
      return state == _videoStateAvailable.default;
    }.property('state'),
    videosCount: _emberData.default.attr('number')
  });

  var _default = Video;
  _exports.default = _default;
});