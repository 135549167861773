define("vts-ember/adapters/application", ["exports", "active-model-adapter", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _activeModelAdapter, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ApplicationAdapter = _activeModelAdapter.default.extend(_dataAdapterMixin.default, {
    // host: 'http://localhost:3333',
    authorizer: 'authorizer:devise',
    bulkCommit: false,
    plurals: {
      gps_datum: "gps_data",
      driver_data: "driver_data"
    },
    rollout: function rollout(model) {
      var url = this.buildURL('task', model.get('id')) + '/rollout';
      return this.ajax(url, 'PUT', {});
    }
  });

  var _default = ApplicationAdapter;
  _exports.default = _default;
});