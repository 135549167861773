define("vts-ember/controllers/devices/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DevicesNewController = Ember.Controller.extend({
    application: Ember.inject.controller(),
    devices: Ember.inject.controller(),
    project: null,
    projects: null,
    isNew: true,
    // validationError: false,
    desc1: '',
    desc2: '',
    desc3: '',
    desc4: '',
    vehicleTypes: [{
      desc: 'Undefined',
      id: 0
    }, {
      desc: 'Car',
      id: 1
    }, {
      desc: 'Car derived van',
      id: 2
    }, {
      desc: 'LCV less than 3.5 tonnes',
      id: 3
    }, {
      desc: 'LCV more than 3.5 tonnes',
      id: 4
    }, {
      desc: 'HGV',
      id: 5
    }],
    vehicleTypeDesc: function () {
      var types = this.get('vehicleTypes');
      var id = this.get('model.vehicleType');
      return types[id].desc;
    }.property('model.vehicleType'),
    additionalHardwareMap: [{
      desc: 'Undefined',
      id: 0
    }, {
      desc: 'MDVR',
      id: 1
    }, {
      desc: 'Connected MDVR',
      id: 2
    }],
    additionalHardwareDesc: function () {
      var map = this.get('additionalHardwareMap');
      var id = this.get('model.additionalHardware');
      return map[id].desc;
    }.property('model.additionalHardware'),
    projectsSorted: function () {
      var projects = this.get('projects');

      if (projects) {
        return projects.toArray().sort(function (a, b) {
          var pa = a.get('projectId');
          var pb = b.get('projectId');
          if (pa < pb) return -1;
          if (pa > pb) return 1;
          return 0;
        });
      } else {
        return null;
      }
    }.property('projects'),
    stopEditing: function stopEditing() {//this.get('model').destroyRecord()
    },
    actions: {
      save: function save() {
        var device = this.get('model');
        var project = this.get('project');

        if (project != null) {
          device.set('projectId', project.id);
        }

        if (this.get('model.validationError')) {
          alert('a valid serial number and project must be selected.');
        } else {
          var desc = [];

          if (this.get('desc1') != '' && this.get('desc1') != null) {
            desc.push(this.get('desc1'));
          }

          if (this.get('desc2') != '' && this.get('desc2') != null) {
            desc.push(this.get('desc2'));
          }

          if (this.get('desc3') != '' && this.get('desc3') != null) {
            desc.push(this.get('desc3'));
          }

          if (this.get('desc4') != '' && this.get('desc4') != null) {
            desc.push(this.get('desc4'));
          }

          var descJson = JSON.stringify(desc);
          device.set('description', descJson);

          var _this = this;

          this.get('model').save().then(function () {
            var recsNo = _this.get('devices.recordsNo');

            _this.set('devices.recordsNo', recsNo + 1);

            _this.transitionToRoute('index');
          }, function () {
            alert('Could not save device.');
          });
        }
      },
      cancel: function cancel() {
        this.get('model').destroyRecord();
        this.transitionToRoute('index');
      },
      updateProject: function updateProject(item) {
        this.set('project', item);
      }
    }
  });
  var _default = DevicesNewController;
  _exports.default = _default;
});