define("vts-ember/routes/video-request/trip", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        trip: this.store.findRecord('trip', params.trip_id),
        gpsData: this.store.query('gpsDatum', {
          trip_id: params.trip_id
        })
      });
    },
    setupController: function setupController(controller, models) {
      this._super(controller, models);
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (!this.get('currentUser.user.canAccessVideoRequests')) {
        this.transitionTo('application');
      }
    }
  });

  _exports.default = _default;
});