define("vts-ember/templates/graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g4+/El25",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"id\",\"chart\"],[9,\"style\",\"height:400px;width:300px; \"],[7],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "vts-ember/templates/graph.hbs"
    }
  });

  _exports.default = _default;
});