define("vts-ember/templates/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KJ7Zs4TQ",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n\\t\"],[6,\"div\"],[9,\"class\",\"text-center\"],[7],[0,\"\\n\\t\\t\"],[6,\"div\"],[9,\"class\",\"lds-grid\"],[7],[0,\"\\n\\t\\t\\t\"],[6,\"div\"],[7],[8],[0,\" \"],[6,\"div\"],[7],[8],[0,\" \"],[6,\"div\"],[7],[8],[0,\"\\n\\t\\t\\t\"],[6,\"div\"],[7],[8],[0,\" \"],[6,\"div\"],[7],[8],[0,\" \"],[6,\"div\"],[7],[8],[0,\"\\n\\t\\t\\t\"],[6,\"div\"],[7],[8],[0,\" \"],[6,\"div\"],[7],[8],[0,\" \"],[6,\"div\"],[7],[8],[0,\"\\n\\t\\t\"],[8],[0,\"\\n\\t\"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "vts-ember/templates/loading.hbs"
    }
  });

  _exports.default = _default;
});