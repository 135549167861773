define("vts-ember/controllers/device-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DeviceConfigController = Ember.Controller.extend({
    application: Ember.inject.controller(),
    actions: {
      configure: function configure() {
        var _this = this;

        this.store.findRecord('configRequest', this.get('model.id')).then(function (config) {
          _this.transitionToRoute('config-request', config);
        }, function () {
          _this.transitionToRoute('config-requests.new', _this.model);
        });
      }
    }
  });
  var _default = DeviceConfigController;
  _exports.default = _default;
});