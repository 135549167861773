define("vts-ember/routes/index", ["exports", "vts-ember/helpers/pollster", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _pollster, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var IndexRoute = Ember.Route.extend(_authenticatedRouteMixin.default, {
    schedule_reload: false,
    project: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    model: function model(params) {
      // return currentUser promise to ensure that currentUser is loaded in setupController
      return this.get('currentUser.promise');
    },
    setupController: function setupController(controller, model) {
      var pid = this.controllerFor('application').get('model') || this.get('project.id') || '000';
      this.set('schedule_reload', pid != '000');
      var params = {
        projectId: pid
      };
      this.set('dev_params', params);
      this.store.query('device', params).then(function (data) {
        controller.set('model', data);
      });

      if (Ember.isNone(this.get('pollster'))) {
        this.set('pollster', _pollster.default.create({
          _this: this,
          onPoll: function onPoll() {
            var _this = this.get('_this');

            var params = _this.get('dev_params');

            var reload = _this.get('schedule_reload');

            if (reload) {
              _this.store.query('device', params).then(function (data) {
                controller.set('model', data);
              });
            }
          }
        }));
      }

      this.get('pollster').start();
    },
    deactivate: function deactivate() {
      this.get('pollster').stop();
    }
  });
  var _default = IndexRoute;
  _exports.default = _default;
});