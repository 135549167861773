define("vts-ember/templates/devices/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JQgepTCL",
    "block": "{\"symbols\":[],\"statements\":[[6,\"form\"],[9,\"class\",\"well form-horizontal\"],[9,\"novalidate\",\"\"],[3,\"action\",[[19,0,[]],\"save\"],[[\"on\"],[\"submit\"]]],[7],[0,\"\\n  \"],[6,\"h2\"],[7],[0,\"Add device\"],[8],[0,\"\\n  \"],[6,\"br\"],[7],[8],[0,\"\\n  \"],[12,\"devices/device-edit-fields\",[]],[0,\"\\n\\n  \"],[6,\"div\"],[9,\"class\",\"form-group\"],[7],[0,\"\\n    \"],[6,\"p\"],[9,\"class\",\"nav pull-right\"],[7],[0,\"\\n      \"],[6,\"button\"],[9,\"type\",\"submit\"],[9,\"class\",\"btn btn-primary\"],[7],[0,\"Add\"],[8],[0,\"\\n      \"],[6,\"button\"],[9,\"class\",\"btn btn-default\"],[3,\"action\",[[19,0,[]],\"cancel\"]],[7],[0,\"Cancel\"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "vts-ember/templates/devices/new.hbs"
    }
  });

  _exports.default = _default;
});