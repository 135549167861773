define("vts-ember/routes/video", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    afterModel: function afterModel(model) {
      if (model.get('requestToken') == "") {
        // reload video to generate the request token
        return this.store.findRecord('video', model.id);
      }
    }
  });

  _exports.default = _default;
});