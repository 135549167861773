define("vts-ember/controllers/project/config/action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ProjectConfigActionController = Ember.Controller.extend({
    application: Ember.inject.controller(),
    devices: Ember.inject.controller(),
    project: null,
    actions: {
      rollout: function rollout() {
        alert('Broadcasting config for project ' + this.get('project.id'));
        this.get('project').rollout().then(function () {
          alert('Success');
        }, function (e) {
          alert('Failed: ' + e);
        });
      }
    }
  });
  var _default = ProjectConfigActionController;
  _exports.default = _default;
});