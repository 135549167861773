define("vts-ember/controllers/video-request/active", ["exports", "vts-ember/controllers/tables"], function (_exports, _tables) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _tables.default.extend({
    application: Ember.inject.controller(),
    queryParams: ['recordsPerPage', 'order'],
    order: 'desc',
    recordsPerPage: 1000,
    actions: {
      delete: function _delete(video) {
        if (window.confirm('Are you sure you want to cancel this request and delete uploaded video data?')) {
          video.deleteRecord(); // TODO: error handling

          video.save().then(function () {
            window.alert('Video deleted');
          });
        }
      }
    }
  });

  _exports.default = _default;
});