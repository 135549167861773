define("vts-ember/routes/project/config/v0", ["exports", "vts-ember/routes/project/config/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ProjectConfigV0Route = _base.default.extend({
    suffix: 'v0'
  });

  var _default = ProjectConfigV0Route;
  _exports.default = _default;
});