define("vts-ember/authenticators/devise", ["exports", "ember-simple-auth/authenticators/devise"], function (_exports, _devise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _devise.default.extend({
    serverTokenEndpoint: '/login',
    // serverTokenEndpoint: 'http://localhost:3333/login',
    authenticate: function authenticate(identification, password) {
      var _this = this;

      if (typeof identification == 'string') {
        return new Promise(function (resolve, reject) {
          var useResponse = _this.get('rejectWithResponse');

          var _this$getProperties = _this.getProperties('resourceName', 'identificationAttributeName', 'tokenAttributeName'),
              resourceName = _this$getProperties.resourceName,
              identificationAttributeName = _this$getProperties.identificationAttributeName,
              tokenAttributeName = _this$getProperties.tokenAttributeName;

          var data = {};
          data[resourceName] = {
            password: password
          };
          data[resourceName][identificationAttributeName] = identification;

          _this.makeRequest(data).then(function (response) {
            if (response.ok) {
              response.json().then(function (json) {
                if (_this._validate(json)) {
                  var _resourceName = _this.get('resourceName');

                  var _json = json[_resourceName] ? json[_resourceName] : json;

                  Ember.run(null, resolve, _json);
                } else {
                  Ember.run(null, reject, "Check that server response includes ".concat(tokenAttributeName, " and ").concat(identificationAttributeName));
                }
              });
            } else {
              if (useResponse) {
                Ember.run(null, reject, response);
              } else {
                response.json().then(function (json) {
                  return Ember.run(null, reject, json);
                });
              }
            }
          }).catch(function (error) {
            return Ember.run(null, reject, error);
          });
        });
      } else {
        var hash = identification;
        return new Promise(function (resolve, reject) {
          if (_this._validate(hash)) {
            var resourceName = _this.get('resourceName');

            var _hash = hash[resourceName] ? hash[resourceName] : hash;

            Ember.run(null, resolve, _hash);
          } else {
            Ember.run(null, reject, "Check that server response includes ".concat(tokenAttributeName, " and ").concat(identificationAttributeName));
          }
        });
      }
    },
    invalidate: function invalidate(data) {
      return this.makeRequest('', {
        url: '/logout'
      });
    }
  });

  _exports.default = _default;
});