define("vts-ember/routes/video-request/trip/preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {// trip: this.store.findRecord('trip', params.trip_id)
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      var models = this.modelFor('video-request.trip'); // this._super(controller, model)

      controller.set('model', models.trip);
      controller.set('gpsData', models.gpsData);
      var start = models.trip.get('start');
      controller.set('videos', this.store.query('video', {
        device: models.trip.get('device.id'),
        start: start,
        stop: models.trip.get('stop'),
        recordsPerPage: 100
      }));
      controller.set('start', start);
      models.trip.get('device').then(function (device) {
        controller.set('project', _this.store.findRecord('project', device.get('projectId')));
      });
    }
  });

  _exports.default = _default;
});