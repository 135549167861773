define("vts-ember/routes/live-check-devices", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),
    project: Ember.inject.service(),
    model: function model(params) {
      if (this.modelFor('application')) {
        params.projectId = this.modelFor('application');
      } else {
        params.projectId = this.get('project.project.id');
      }

      return this.store.query('device', params);
    },
    setupController: function setupController(controller, model) {
      controller.set('currentPage', 1);
      controller.set('recordsNo', model.get('length'));

      this._super(controller, model);
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (!this.get('currentUser.user.canAccessLiveChecks')) {
        this.transitionTo('application');
      }
    },
    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});