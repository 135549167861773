define("vts-ember/templates/videos/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b+s3MFDk",
    "block": "{\"symbols\":[],\"statements\":[[4,\"bs-modal\",null,[[\"title\",\"onHide\"],[\"Video\",[25,\"action\",[[19,0,[]],\"close\"],null]]],{\"statements\":[[0,\"  \"],[1,[25,\"videojs-player\",null,[[\"src\",\"type\",\"controls\",\"playsinline\",\"fluid\"],[[20,[\"model\",\"mp4Link\"]],\"video/mp4\",true,true,true]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "vts-ember/templates/videos/show.hbs"
    }
  });

  _exports.default = _default;
});