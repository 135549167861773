define("vts-ember/controllers/tables", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TablesController = Ember.Controller.extend({
    recordsNo: 0,
    recordsPerPage: 50,
    currentPage: 1,
    pageOptions: [10, 20, 50, 100],
    goto: 1,
    records: null,
    theFilter: '',
    currentPageChanged: Ember.observer('currentPage', function () {
      this.set('goto', this.get('currentPage'));
    }),
    disablePrevious: Ember.computed('recordsPerPage', 'recordsNo', 'currentPage', 'pagesNo', function () {
      var recordsNo = this.get('recordsNo');
      var recordsPerPage = this.get('recordsPerPage');
      var currentPage = this.get('currentPage');

      if (recordsNo <= recordsPerPage) {
        return true;
      }

      if (currentPage <= 1) {
        return true;
      }

      return false;
    }),
    pagesNo: function () {
      var recordsNo = this.get('recordsNo');
      var recordsPerPage = this.get('recordsPerPage');
      var num = recordsNo % recordsPerPage;
      var div = recordsNo / recordsPerPage | 0;
      var pages = 0;
      pages = num != 0 ? div + 1 : div;
      return pages;
    }.property('recordsNo', 'recordsPerPage'),
    disableNext: function () {
      var currentPage = this.get('currentPage');
      var pagesNo = this.get('pagesNo');

      if (currentPage >= pagesNo) {
        return true;
      } else {
        return false;
      }
    }.property('currentPage', 'pagesNo'),

    /* filtering and sorting */
    checkFilterMatch: function checkFilterMatch(theObject, str) {
      var index, re;
      var keys = this.get('filterParams'); // old regexp: logic OR
      // var re = new RegExp('(' + str.split(" ").filter(String).join('|') + ')', 'i');

      /* create regex to search for all of the words in the filter field, separated with spaces */

      var arr = str.split(' ').filter(String);

      try {
        if (arr.length > 1) {
          re = new RegExp('(?=.*' + arr.join(')(?=.*') + ')', 'i');
        } else {
          re = new RegExp(arr[0], 'i');
        }
      } catch (err) {
        // on RegExp error ignore the filter string
        return true;
      }

      arr = [];

      for (index = 0; index < keys.length; index++) {
        // if (theObject.get(keys[index]).toString().match(re) != null) 
        arr.push(theObject.get(keys[index]).toString());
      }

      return arr.join(' ').match(re);
    },
    recordsDisplayed: function () {
      var records = [];
      var currentPage = this.get('currentPage');
      var recordsPerPage = this.get('recordsPerPage');
      var start = (currentPage - 1) * recordsPerPage;
      var sortedArr = this.get('sortedContent');

      if (sortedArr) {
        sortedArr = sortedArr.toArray();
        records = sortedArr.slice(start, start + recordsPerPage);
      }

      return records;
    }.property('currentPage', 'recordsPerPage', 'recordsNo', 'sortedContent'),
    sortDefinition: Ember.computed('sortBy', 'sortAscending', function () {
      return [this.get('sortBy') + (this.get('sortAscending') ? '' : ':desc')];
    }),
    sortedContent: Ember.computed.sort('filteredContent', 'sortDefinition'),
    filteredContent: function () {
      var ac = this.get('model');

      if (ac) {
        var fc = ac.filter(function (_this) {
          return function (theObject, index, enumerable) {
            if (_this.get('theFilter')) {
              return _this.checkFilterMatch(theObject, _this.get('theFilter'));
            } else {
              return true;
            }
          };
        }(this));
        return fc;
      } else {
        return null;
      }
    }.property('theFilter', 'model.[]', 'sortBy', 'sortAscending'),
    actions: {
      goPage: function goPage() {
        var currentPage = parseInt(this.get('goto'));
        var pagesNo = this.get('pagesNo');

        if (currentPage >= 1 && currentPage <= pagesNo) {
          this.set('currentPage', currentPage);
        }
      },
      first: function first() {
        this.set('currentPage', 1);
      },
      last: function last() {
        this.set('currentPage', this.get('pagesNo'));
      },
      next: function next() {
        var currentPage = this.get('currentPage') + 1;
        var pagesNo = this.get('pagesNo');

        if (currentPage <= pagesNo) {
          this.set('currentPage', currentPage);
        }
      },
      previous: function previous() {
        var currentPage = this.get('currentPage') - 1;

        if (currentPage >= 1) {
          this.set('currentPage', currentPage);
        }
      },
      sortBy: function sortBy(property) {
        if (this.get('sortBy').startsWith(property)) {
          var ascending = this.get('sortAscending');
          this.set('sortAscending', !ascending);
        }

        this.set('sortBy', property);
      }
    }
  });
  var _default = TablesController;
  _exports.default = _default;
});