define("vts-ember/instance-initializers/sso", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    var _application$containe = application.container,
        container = _application$containe === void 0 ? application : _application$containe;
    var session = container.lookup('service:session');
    var attributes = $('meta[name="current-user"]').attr('content');

    if (attributes) {
      var hash = JSON.parse(attributes);
      session.authenticate('authenticator:devise', hash).catch(function (reason) {
        console.log('Reject reason', reason);
      });
    }
  }

  ;
  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});