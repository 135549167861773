define("vts-ember/controllers/offline", ["exports", "vts-ember/controllers/devices"], function (_exports, _devices) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var DevicesMainController = _devices.default.extend({
    application: Ember.inject.controller(),
    currentUser: Ember.inject.service(),
    settings: Ember.inject.controller(),
    queryParams: ['search', 'sortWith', 'order'],
    sortBy: 'lastConnectedFormatted',
    filterParams: ['serial', 'fwVersion', 'fwBuild', 'licensePlate', 'lastConnectedFormatted', 'stateTooltip'],
    sortByLastConnectedFormatted: function () {
      return this.get('sortBy') == 'lastConnectedFormatted';
    }.property('sortBy'),
    isOutOfService: function isOutOfService(theObject) {
      var today = new Date();
      var milliseconds = Math.abs(today - theObject.get('lastConnected'));
      var days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));
      var histDays = this.get('settings.histDays');

      if ((theObject.get('state') === 'disconnected' || theObject.get('state') === 'offline') && days >= histDays) {
        return true;
      }

      return false;
    },
    filteredContent: function () {
      var ac = this.get('model');

      if (ac) {
        var fc = ac.filter(function (_this) {
          return function (theObject, index, enumerable) {
            if (!_this.isOutOfService(theObject)) {
              return false;
            }

            if (_this.get('theFilter')) {
              return _this.checkFilterMatch(theObject, _this.get('theFilter'));
            } else {
              return true;
            }
          };
        }(this));
        this.set('recordsNo', fc.length);
        return fc;
      } else {
        return null;
      }
    }.property('arrangedContent', 'recordsNo', 'theFilter', 'sortBy', 'sortAscending', 'content.[]')
  });

  var _default = DevicesMainController;
  _exports.default = _default;
});