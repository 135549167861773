define("vts-ember/controllers/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SettingsController = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    distanceUnits: ['km', 'mi'],
    timezones: ['NZDT', 'NZT', 'AEDT', 'AEST', 'ACDT', 'ACST', 'AWST', 'MYT', 'GST', 'AST', 'SAST', 'MET', 'GMT', 'ATLST', 'EST', 'CST', 'MST', 'MST-Arizona', 'PST', 'CLT', 'HST', 'EET'],
    languages: ['English'],
    defaultUnit: 'km',
    defaultTimezone: 'MET',
    defaultLanguage: 'English',
    defaultHistoryDays: 3,
    histDays: 3,
    actions: {
      setDefault: function setDefault() {
        if (window.confirm('Are you sure you want to reset your settings to default?')) {
          var setting = this.get('model');
          setting.set('distanceUnit', this.get('defaultUnit'));
          setting.set('timezone', this.get('defaultTimezone'));
          setting.set('language', this.get('defaultLanguage'));
          setting.set('historyDays', this.get('defaultHistoryDays'));
        }
      },
      save: function save() {
        this.get('model').save().then(function () {
          window.alert('You may have to refresh your page for the new settings to be applied.');
        }, function () {
          window.alert('Failed to save settings.');
        });
        this.transitionToRoute('index');
      },
      cancel: function cancel() {
        this.transitionToRoute('index');
      }
    }
  });
  var _default = SettingsController;
  _exports.default = _default;
});