define("vts-ember/models/video-state-requested", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VIDEO_STATE_REQUESTED = 'requested';
  var _default = VIDEO_STATE_REQUESTED;
  _exports.default = _default;
});