define("vts-ember/helpers/searchable-select-get", ["exports", "ember-searchable-select/helpers/searchable-select-get"], function (_exports, _searchableSelectGet) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _searchableSelectGet.default;
    }
  });
  Object.defineProperty(_exports, "searchableSelectGet", {
    enumerable: true,
    get: function get() {
      return _searchableSelectGet.searchableSelectGet;
    }
  });
});