define("vts-ember/routes/users/user", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      return this.store.findRecord('user', params.user_id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var projects = this.store.peekAll('project').filterBy('isTempOrAdmin', false);
      controller.set('projects', projects);
      controller.set('selectedProject', model.get('projectId'));
      controller.set('devices', this.store.query('device', {
        projectId: model.get('projectId')
      }));
      controller.set('isEditing', false);
      controller.startEditing();
    }
  });

  _exports.default = _default;
});