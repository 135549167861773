define("vts-ember/controllers/video-request/trip/request", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    cameraOptions: [{
      label: 'Camera 1',
      value: 1
    }, {
      label: 'Camera 2',
      value: 2
    }],
    requestLengthMap: {
      10: '10 seconds',
      30: '30 seconds',
      60: '1 minute'
    },
    requestLengthOptions: [{
      label: '10 seconds',
      value: 10
    }, {
      label: '30 seconds',
      value: 30
    }, {
      label: '1 minute',
      value: 60
    }],
    camera: 1,
    range: {
      'min': 10,
      '50%': 30,
      'max': 60
    },
    requestLength: 10,
    requestLengthFormatted: function () {
      return this.get('requestLengthMap')[this.get('requestLength')];
    }.property('requestLength'),
    start: null,
    startString: function () {
      var start = this.get('start');

      if (start) {
        return this.get('start').toString();
      } else {
        return '';
      }
    }.property('start'),
    remainingDeviceTime: Ember.computed('project', 'model', function () {
      var project = this.get('project');

      if (project) {
        var deviceTime = project.get('videoRequestDeviceLimit') - this.get('model.device.videoRequestCurrentUsage');

        if (isNaN(deviceTime)) {
          deviceTime = 0;
        }

        return deviceTime;
      }

      return 0;
    }),
    remainingProjectTime: Ember.computed('project', 'model', function () {
      var project = this.get('project');

      if (project) {
        var projectTime = project.get('videoRequestProjectLimit') - project.get('videoRequestCurrentUsage');

        if (isNaN(projectTime)) {
          projectTime = 0;
        }

        return projectTime;
      }

      return 0;
    }),
    remainingRequestTime: function () {
      var projectTime = this.get('remainingProjectTime');
      var deviceTime = this.get('remainingDeviceTime');
      var timeLeft = projectTime < deviceTime ? projectTime : deviceTime;
      return timeLeft < 0 ? 0 : timeLeft;
    }.property('remainingProjectTime', 'remainingDeviceTime'),
    format: {
      to: function to(value) {
        // minutes and seconds
        var mins = ~~(duration % 3600 / 60);
        var secs = ~~duration % 60;
        ret = "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        return ret;
      },
      from: function from(value) {
        return +value;
      }
    },
    actions: {
      close: function close() {
        this.transitionToRoute('video-request.trip');
      },
      startTimeChanged: function startTimeChanged(startTime) {
        var tm = new Date(startTime);
        var tripStart = new Date(this.get('model.start'));
        var tripStop = new Date(this.get('model.stop'));

        if (tm > tripStop) {
          tm = tripStop;
        }

        if (tm < tripStart) {
          tm = tripStart;
        }

        this.set('start', tm);
      },
      changedAction: function changedAction(a) {
        this.set('requestLength', parseInt(a));
      },
      trackClicked: function trackClicked(startDate) {
        this.set('start', startDate);
      },
      requestVideo: function requestVideo() {
        var ts = this.get('start');
        var length = this.get('requestLength');
        var camera = this.get('camera');

        if (ts == null) {
          window.alert('Please select a startpoint for the video request by clicking on the map.');
          return;
        }

        if (this.get('remainingRequestTime') == 0) {
          var projectTime = this.get('remainingProjectTime');

          if (projectTime < length) {
            window.alert('You have reached the monthly data allocation for this device.');
            return;
          } else {
            if (window.confirm('You have reached the monthly data allocation for ' + 'this device. If you wish to assign more minutes to this device ' + 'from your data pool, please request it here. Any data is deducted ' + 'from your overall project data pool.')) {// This just falls through, requesting the video anyway. 
              // Server will send a notification to the CS team based on
              // no more seconds for the device available
            } else {
              return;
            }
          }
        }

        if (window.confirm('In order to submit a Video Request, the device must be active. ' + 'A Video Request for an active device will be submitted, and begin uploading immediately. ' + 'A Video Request for an inactive device will be submitted, and queued for upload immediately ' + 'upon the device powering on. ' + 'For an inactive device that requires an immediate Video Request, please turn on the vehicle’s ignition.')) {
          var _this = this;

          var xhttp = new XMLHttpRequest();

          xhttp.onreadystatechange = function () {
            if (xhttp.readyState === 4 && xhttp.status === 200) {
              window.alert('Video request successful, footage will be transfered when the device is online.');

              _this.transitionToRoute('video-request.trip');
            } else if (xhttp.readyState === 4 && xhttp.status === 400) {
              window.alert('Video request not successful.');

              _this.transitionToRoute('video-request.trip');
            }
          };

          xhttp.open('POST', '/videos', true);
          xhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
          this.get('session').authorize('authorizer:devise', function (headerName, headerValue) {
            var headers = {};
            headers[headerName] = headerValue;
            xhttp.setRequestHeader(headerName, headerValue);
          });
          var serial = this.get('model.device.serial');
          xhttp.send('device_id=' + serial + '&start=' + ts.toISOString() + '&length=' + length + '&camera=' + camera + '&split=60');
        }
      }
    }
  });

  _exports.default = _default;
});