define("vts-ember/controllers/live-check-devices", ["exports", "vts-ember/controllers/tables"], function (_exports, _tables) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var LiveCheckDevicesController = _tables.default.extend({
    sortBy: 'stateTooltip',
    sortAscending: false,
    filterParams: ['serial', 'fwVersion', 'fwBuild', 'licensePlate', 'zone', 'homeDepot', 'projectId', 'stateTooltip'],
    actions: {
      /* ugly bunch of functions used to display search direction arrows on the correct column only */
      sortBySerial: function () {
        return this.get('sortBy') == 'serial';
      }.property('sortBy'),
      sortByFwBuild: function () {
        return this.get('sortBy') == 'fwBuild';
      }.property('sortBy'),
      sortByLicensePlate: function () {
        return this.get('sortBy') == 'licensePlate';
      }.property('sortBy'),
      sortByZone: function () {
        return this.get('sortBy') == 'zone';
      }.property('sortBy'),
      sortByProjectId: function () {
        return this.get('sortBy') == 'projectId';
      }.property('sortBy'),
      sortByStateTooltip: function () {
        return this.get('sortBy') == 'stateTooltip';
      }.property('sortBy')
    }
  });

  var _default = LiveCheckDevicesController;
  _exports.default = _default;
});