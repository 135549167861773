define("vts-ember/models/video-state-removed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VIDEO_STATE_REMOVED = 'removed';
  var _default = VIDEO_STATE_REMOVED;
  _exports.default = _default;
});