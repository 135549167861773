define("vts-ember/controllers/projects-show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ProjectsShowController = Ember.Controller.extend({
    application: Ember.inject.controller(),
    devices: Ember.inject.controller(),
    project: Ember.inject.service(),
    config: null,
    ok: function ok() {
      this.transitionToRoute('projects');
    },
    setActiveProject: function setActiveProject() {
      this.set('project.project', this.get('model'));
      this.get('devices').clearSelection();
      this.get('users').clearSelection();
      this.set('application.model', this.get('model.id'));
      this.set('application.projectId', this.get('model.id'));
    }
  });
  var _default = ProjectsShowController;
  _exports.default = _default;
});