define("vts-ember/routes/trip", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      return Ember.RSVP.hash({
        trip: this.store.findRecord('trip', params.trip_id),
        gpsData: this.store.query('gpsDatum', {
          trip_id: params.trip_id
        })
      });
    },
    setupController: function setupController(controller, models) {
      var start = models.trip.get('start');
      controller.set('model', models.trip);
      controller.set('gpsData', models.gpsData);
      controller.set('videos', this.store.query('video', {
        device: models.trip.get('device.id'),
        start: start,
        stop: models.trip.get('stop'),
        recordsPerPage: 100
      }));
      controller.set('requestTime', start.toString());
    }
  });

  _exports.default = _default;
});