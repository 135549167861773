define("vts-ember/models/gps-datum", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var GpsDatum = _emberData.default.Model.extend({
    longitude: _emberData.default.attr('number'),
    latitude: _emberData.default.attr('number'),
    time: _emberData.default.attr('date'),
    status: _emberData.default.attr('string'),
    timeFormatted: function () {
      var time = this.get('time');

      if (time != undefined) {
        return (0, _moment.default)(time).format('yyyy-MM-DD H:mm:ss');
      }

      return null;
    }.property('time') // device: DS.belongsTo('device')

  });

  var _default = GpsDatum;
  _exports.default = _default;
});