define("vts-ember/templates/config-requests/config-requests", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ibaTUsgK",
    "block": "{\"symbols\":[],\"statements\":[[6,\"form\"],[9,\"class\",\"well form-horizontal\"],[7],[0,\"\\n  \"],[6,\"h2\"],[7],[0,\"Device configurations\"],[8],[0,\"\\n  \"],[6,\"dl\"],[9,\"class\",\"dl-horizontal\"],[7],[0,\"\\n    \"],[6,\"dt\"],[7],[0,\"Timezone\"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"timezone\"]]],null,{\"statements\":[[0,\"      \"],[6,\"dd\"],[7],[1,[18,\"timezone\"],false],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[6,\"dd\"],[7],[0,\"Automatic\"],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[8],[0,\"\\n  \"],[6,\"dl\"],[9,\"class\",\"dl-horizontal\"],[7],[0,\"\\n    \"],[6,\"dt\"],[7],[0,\"Language\"],[8],[0,\"\\n    \"],[6,\"dd\"],[7],[1,[18,\"language\"],false],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"dl\"],[9,\"class\",\"dl-horizontal\"],[7],[0,\"\\n    \"],[6,\"dt\"],[7],[0,\"Measurment\"],[8],[0,\"\\n    \"],[6,\"dd\"],[7],[1,[18,\"measurment\"],false],[8],[0,\"\\n  \"],[8],[0,\"\\n\\n  \"],[6,\"div\"],[7],[0,\"\\n    \"],[6,\"p\"],[9,\"class\",\"nav pull-right\"],[7],[0,\"\\n      \"],[6,\"button\"],[9,\"id\",\"editSettings\"],[9,\"class\",\"btn btn-default\"],[3,\"action\",[[19,0,[]],\"edit\"]],[7],[0,\"Edit\"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"p\"],[9,\"class\",\"nav pull-left\"],[7],[0,\"\\n      \"],[6,\"button\"],[9,\"id\",\"editPassword\"],[9,\"class\",\"btn btn-default\"],[3,\"action\",[[19,0,[]],\"edit\"]],[7],[0,\"Edit password\"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "vts-ember/templates/config-requests/config-requests.hbs"
    }
  });

  _exports.default = _default;
});