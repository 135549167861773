define("vts-ember/components/video-request-device", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VideoRequestDeviceComponent = Ember.Component.extend({
    tagName: ''
  });
  var _default = VideoRequestDeviceComponent;
  _exports.default = _default;
});