define("vts-ember/routes/vehicle-statistics", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    queryParams: {
      currentPage: {
        refreshModel: true
      },
      recordsPerPage: {
        refreshModel: true
      },
      sortWith: {
        refreshModel: true
      },
      order: {
        refreshModel: true
      },
      range: {
        refreshModel: true
      }
    },
    project: Ember.inject.service(),
    model: function model(params) {
      if (this.modelFor('application')) {
        params.projectId = this.modelFor('application');
      } else {
        params.projectId = this.get('project.project.id');
      }

      return this.store.query('driverDatum', params);
    },
    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting) {
        // isExiting would be false if only the route's model was changing
        controller.set('currentPage', 1);
      }
    },
    setupController: function setupController(controller, model) {
      controller.set('projectId', this.get('project.project.id'));

      this._super(controller, model);

      controller.set('recordsNo', model.get('meta').total);
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      var app = Ember.getOwner(this).lookup('controller:application');

      if (!app.get('statisticsAllowed')) {
        this.transitionTo('application');
      }
    },
    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});