define("vts-ember/router", ["exports", "vts-ember/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    // # USER SETTINGS
    this.route('settings', function () {
      '/settings';
    }); // # MAIN MENU ITEMS
    // ## Devices

    this.route('devices', function () {
      '/devices';
    });
    this.route('device', {
      path: '/devices/:device_id'
    });
    this.route('devices.new', {
      path: '/devices/new'
    });
    this.route('device-config', {
      path: '/device-config/:device_config_id'
    });
    this.route('config-request', {
      path: '/config-requests/:config_id'
    });
    this.route('config-requests');
    this.route('config-requests.new', {
      path: '/config-requests/:config_id/new'
    }); // ## Incidents

    this.route('incidents');
    this.route('incident', {
      path: '/incidents/:incident_id'
    }); // ## Events

    this.route('events', function () {
      this.route('events', {
        path: '/events/:device_id'
      });
    }); // ## Videos

    this.route('videos', function () {
      this.route('show', {
        path: '/show/:video_id'
      });
      this.route('videos', {
        path: '/videos/:device_id'
      });
    });
    this.route('video', {
      path: '/video/:video_id'
    }); // ## Trips

    this.route('trips', function () {
      this.route('trip', {
        path: '/trips/:device_id'
      });
    });
    this.route('trip', {
      path: '/trip/:trip_id'
    }); // ## Live Check

    this.route('live-check-devices', {
      path: '/live-check/devices'
    });
    this.route('live-check', {
      path: '/live-check/:device_id'
    }); // ## Video Request

    this.route('video-request', function () {
      this.route('new');
      this.route('trips', {
        path: '/trips/:device_id'
      });
      this.route('trip', {
        path: '/trip/:trip_id'
      }, function () {
        this.route('preview');
        this.route('request');
      });
      this.route('active', {
        path: '/active'
      });
      this.route('complete', {
        path: '/complete'
      });
      this.route('previews', {
        path: '/previews'
      }, function () {
        this.route('show', {
          path: ':video_id'
        });
      });
    }); // # STATISTICS

    this.route('vehicle-statistics'); // # PROJECTS

    this.route('projects', function () {
      this.route('new');
    });
    this.route('project', {
      path: '/projects/:project_id'
    }, function () {
      this.route('config', {
        path: '/config'
      }, function () {
        this.route('action');
        this.route('v0');
        this.route('v1');
        this.route('v2');
        this.route('v3');
        this.route('v4');
        this.route('v5');
      });
    }); // # USERS

    this.route('users', function () {
      '/users';
    });
    this.route('users.user', {
      path: '/users/:user_id'
    });
    this.route('users.new', {
      path: '/users/new'
    }); // # MAINTENANCE
    // ## Connection Lost

    this.route('connection-lost'); // ## Offline

    this.route('offline', {
      path: '/offline'
    }); // ## Error Status

    this.route('error-status');
    this.route('login');
  });
  var _default = Router;
  _exports.default = _default;
});