define("vts-ember/routes/project/config/action", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    setupController: function setupController(controller, model) {
      var project = this.modelFor('project');
      controller.set('project', project);

      if (project == null) {
        console.log('(setupController action) project not set');
      }
    }
  });

  _exports.default = _default;
});