define("vts-ember/models/video-state-available", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VIDEO_STATE_AVAILABLE = 'available';
  var _default = VIDEO_STATE_AVAILABLE;
  _exports.default = _default;
});