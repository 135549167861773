define("vts-ember/models/project", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Project = _emberData.default.Model.extend({
    projectId: _emberData.default.attr('string'),
    projectName: _emberData.default.attr('string'),
    devicesNo: _emberData.default.attr('number'),
    advancedMap: _emberData.default.attr('boolean'),
    billingCode: _emberData.default.attr('string'),
    extendedWarranty: _emberData.default.attr('boolean'),
    customerName: _emberData.default.attr('string'),
    platform: _emberData.default.attr('string'),
    owner: _emberData.default.attr('string'),
    customerNumber: _emberData.default.attr('string'),
    defaultVideoFormat: _emberData.default.attr('string'),
    videoRequestProjectLimit: _emberData.default.attr('number'),
    videoRequestCurrentUsage: _emberData.default.attr('number'),
    videoRequestDeviceLimit: _emberData.default.attr('number'),
    dummy: 0,
    projectIdName: function () {
      return this.get('projectId') + ' ' + this.get('projectName');
    }.property('projectId', 'projectName'),
    projectIdClean: function () {
      var pattern = /^[a-zA-Z0-9]+$/i;
      var projectId = this.get('projectId');

      if (projectId != null) {
        if (projectId.match(pattern) == null) {
          return 'Project ID not allowed! Project ID can contain only alphanumeric characters!';
        }
      }

      return '';
    }.property('projectId'),
    projectBillingCodeClean: function () {
      var pattern = /^[a-zA-Z0-9]+$/i;
      var projectId = this.get('billingCode'); // if (projectId != null)
      // if(projectId.match(pattern) == null) {
      //   return 'Project ID not allowed! Project ID can contain only alphanumeric characters!';
      // }

      return '';
    }.property('billingCode'),
    projectNameClean: function () {
      var pattern = /^([a-zA-Z0-9 _-]+)$/i; // allows _ - and space

      var projectName = this.get('projectName');

      if (projectName != null) {
        if (projectName.match(pattern) == null || projectName == ' ') {
          return 'Project name must be a combination of only alphanumeric characters, dashes, underscores and spaces!';
        } else {
          return '';
        }
      }

      return '';
    }.property('projectName'),
    platformFormatted: function () {
      var platform = this.get('platform');

      switch (platform) {
        case "VTS":
          return "VTS";

        case "VIEW":
          return "Key View";

        case "VIEW_PRO":
          return "Key View Pro";

        case "SURECAM_VIEW":
          return "SureCam View";

        case "SURECAM_VIEW_PRO":
          return "SureCam View Pro";

        case "Camtrak":
          return "Camtrak";

        case "Geotab":
          return "Geotab";

        case "Geotab_Pro":
          return "Geotab Pro";

        default:
          return "undefined";
      }
    }.property('platform'),
    validationError: function () {
      if (this.get('projectIdClean') == '' && this.get('projectNameClean') == '' && this.get('projectId') != null && this.get('projectName') != null) {
        return false;
      } else {
        return true;
      }
    }.property('projectIdClean', 'projectNameClean', 'projectId', 'projectName'),
    isTempOrAdmin: function () {
      if (this.get('id') != null || this.get('id') != '000') {
        return false;
      } else {
        return true;
      }
    }.property('id'),
    rollout: function rollout() {
      // const adapter = this.get('store').get('_adapter');
      var adapter = this.store.adapterFor('project');
      return adapter.rollout(this);
    }
  });

  var _default = Project;
  _exports.default = _default;
});