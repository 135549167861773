define("vts-ember/controllers/incidents", ["exports", "vts-ember/controllers/tables"], function (_exports, _tables) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var IncidentsController = _tables.default.extend({
    application: Ember.inject.controller(),
    queryParams: ['currentPage', 'recordsPerPage', 'sortWith', 'order', 'startAt', 'endAt'],
    startDate: Ember.computed('startAt', function () {
      return new Date(this.get('startAt') || Date.now() - 1000 * 60 * 60 * 24);
    }),
    endDate: Ember.computed('endAt', function () {
      return new Date(this.get('endAt') || Date.now());
    }),
    sortBy: 'start',
    sortAscending: false,
    selectedIncident: null,
    sortWith: 'timestamp',
    order: 'desc',
    sortById: function () {
      return this.get('sortWith') == 'id';
    }.property('sortWith'),
    sortByTimestamp: function () {
      return this.get('sortWith') == 'timestamp';
    }.property('sortWith'),
    actions: {
      sortChange: function sortChange(item) {
        if (item == this.sortWith) {
          if (this.order === 'desc') {
            this.set('order', 'asc');
            this.set('sortAscending', true);
          } else {
            this.set('order', 'desc');
            this.set('sortAscending', false);
          }
        }

        this.set('sortWith', item);
      }
    }
  });

  var _default = IncidentsController;
  _exports.default = _default;
});