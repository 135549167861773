define("vts-ember/services/current-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ensurePromise(x) {
    return new Ember.RSVP.Promise(function (resolve) {
      resolve(x);
    });
  }

  var _default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),
    project: Ember.inject.service('project'),
    load: function load() {
      var userId = this.get('session.data.authenticated.email');

      var _this = this;

      if (!Ember.isEmpty(userId)) {
        var app = Ember.getOwner(_this).lookup('controller:application');

        var store = _this.get('store');

        var promise = store.findRecord('user', userId);
        promise.then(function (user) {
          _this.set('user', user); // this was just copied over from old initializer code, this will need a cleanup!


          var role = user.get('role');

          if (role === 'admin') {
            Ember.getOwner(_this).lookup('controller:projects.index').set('isEditable', true);
            Ember.getOwner(_this).lookup('controller:devices').set('isEditable', true);
            Ember.getOwner(_this).lookup('controller:users').set('isEditable', true);

            _this.get('project').setId('000');

            _this.get('project').setSelected(null); // only admin can create admin


            Ember.getOwner(_this).lookup('controller:users.user').set('rolesOptions', ['project admin', 'user', 'admin']);
            Ember.getOwner(_this).lookup('controller:users.new').set('rolesOptions', ['project admin', 'user', 'admin']);
            app.set('canAddAdminUser', true);
            app.set('canAddDevices', true);
            app.set('canAddProjects', true);
            app.set('canAddUsers', true);
            app.set('canConfigDevice', true);
            app.set('canConfigDeviceSuperuser', true);
            app.set('canEditProject', true);
            app.set('develStatistics', true);
            app.set('logsAllowed', true);
            app.set('noFullMap', true);
            app.set('projectsAllowed', true);
            app.set('statisticsAllowed', true);
            app.set('usersAllowed', true);
            app.set('canEditAdditionalHardware', true);
            app.set('canEditDeviceHistory', true);
            app.set('canViewTripDelay', true);
            app.set('canViewLiveChecks', true);
            app.set('canGrantLiveCheckPermission', true);
            app.set('canGrantVideoRequestPermission', true);
          } else if (role === 'project admin') {
            Ember.getOwner(_this).lookup('controller:devices').set('isEditable', true);
            Ember.getOwner(_this).lookup('controller:projects.index').set('isEditable', false);
            Ember.getOwner(_this).lookup('controller:users').set('isEditable', true);
            var proj = user.get('_internalModel._data.projectId');
            Ember.getOwner(_this).lookup('controller:devices.new').set('selectedProject', proj);

            _this.get('project').setId(proj);

            store.findRecord('project', proj).then(function (project) {
              _this.get('project').setSelected(project);

              Ember.getOwner(_this).lookup('controller:users.new').set('selectedProject', project);
            });
            app.set('canAddDevices', true);
            app.set('canAddProjects', false);
            app.set('canAddUsers', true);
            app.set('canConfigDevice', true);
            app.set('statisticsAllowed', true);
            app.set('usersAllowed', true);
            var pids = user.get('consumerPids');

            if (pids) {
              ensurePromise(pids).then(function () {
                app.set('projectsAllowed', true);
                app.set('canEditProject', true);
              });
            }
          } else if (role === 'user') {
            var project_id = user.get('_internalModel._data.projectId');

            if (project_id != null && project_id !== '000') {
              _this.get('project').setId(project_id);

              store.queryRecord('project', project_id).then(function (project) {
                _this.get('project').setSelected(project);
              });
            } // getOwner(_this).lookup('controller:devices').set('isEditable', false);
            // getOwner(_this).lookup('controller:projects.index').set('isEditable', false);
            // getOwner(_this).lookup('controller:users').set('isEditable', false);

          }

          store.findRecord('setting', user.get('email')).then(function (setting) {
            Ember.getOwner(_this).lookup('controller:trips').set('unit', setting.get('distanceUnit'));
            Ember.getOwner(_this).lookup('controller:trip').set('unit', setting.get('distanceUnit'));
            Ember.getOwner(_this).lookup('controller:vehicle-statistics').set('unit', setting.get('distanceUnit'));
            Ember.getOwner(_this).lookup('controller:video-request.trips').set('unit', setting.get('distanceUnit'));
            Ember.getOwner(_this).lookup('controller:settings').set('histDays', setting.get('historyDays'));

            _this.set('setting', setting);
          });
        });
        this.set('promise', promise);
        return promise;
      } else {
        return Ember.RSVP.resolve();
      }
    }
  });

  _exports.default = _default;
});