define("vts-ember/controllers/project/config/v3", ["exports", "vts-ember/controllers/projects/config"], function (_exports, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ProjectConfigV3Controller = _config.default.extend({});

  var _default = ProjectConfigV3Controller;
  _exports.default = _default;
});