define("vts-ember/templates/video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pwdjFXoa",
    "block": "{\"symbols\":[\"modal\",\"footer\"],\"statements\":[[4,\"bs-modal\",null,[[\"onHide\",\"size\"],[[25,\"action\",[[19,0,[]],\"close\"],null],\"lg\"]],{\"statements\":[[4,\"component\",[[19,1,[\"header\"]]],null,{\"statements\":[[0,\"    \"],[6,\"h4\"],[9,\"class\",\"modal-title\"],[7],[1,[20,[\"model\",\"device\",\"serial\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"component\",[[19,1,[\"body\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n        \"],[1,[25,\"video-player\",null,[[\"src\",\"onTimeUpdate\",\"offset\"],[[20,[\"model\",\"mp4Link\"]],[25,\"action\",[[19,0,[]],\"timeUpdate\"],null],[20,[\"offset\"]]]]],false],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"component\",[[19,1,[\"footer\"]]],null,{\"statements\":[[4,\"if\",[[20,[\"model\",\"isDownloadable\"]]],null,{\"statements\":[[0,\"      \"],[6,\"a\"],[10,\"href\",[20,[\"model\",\"linkWithToken\"]],null],[7],[0,\"\\n        \"],[6,\"button\"],[9,\"type\",\"button\"],[9,\"class\",\"btn btn-default\"],[7],[6,\"i\"],[9,\"class\",\"glyphicon glyphicon-download\"],[7],[8],[0,\" Download\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[4,\"bs-button\",null,[[\"onClick\",\"type\"],[[25,\"action\",[[19,0,[]],[19,1,[\"close\"]]],null],\"danger\"]],{\"statements\":[[0,\"Close\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "vts-ember/templates/video.hbs"
    }
  });

  _exports.default = _default;
});