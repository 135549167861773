define("vts-ember/components/login-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var _this$getProperties = this.getProperties('identification', 'password'),
            identification = _this$getProperties.identification,
            password = _this$getProperties.password;

        this.get('session').authenticate('authenticator:devise', identification, password).catch(function (reason) {
          _this.set('errorMessage', 'Invalid username or password');
        });
      }
    }
  });

  _exports.default = _default;
});