define("vts-ember/templates/device-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t6Y4KJkF",
    "block": "{\"symbols\":[],\"statements\":[[6,\"form\"],[9,\"class\",\"form-horizontal\"],[9,\"novalidate\",\"\"],[3,\"action\",[[19,0,[]],\"save\"],[[\"on\"],[\"submit\"]]],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"well\"],[7],[0,\"\\n    \"],[6,\"h2\"],[7],[0,\"Current device configuration for \"],[1,[20,[\"model\",\"device\",\"serial\"]],false],[8],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"model\",\"device\",\"hasConfiguration\"]]],null,{\"statements\":[[0,\"      \"],[12,\"device_config/config_fields\",[]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"alert alert-danger\"],[7],[0,\"\\n        Device configuration is not supported by the current version of the application.\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[6,\"p\"],[9,\"class\",\"nav pull-right\"],[7],[0,\"\\n      \"],[4,\"link-to\",[\"device\",[20,[\"model\",\"device\"]]],[[\"tagName\",\"class\"],[\"button\",\"btn btn-default\"]],{\"statements\":[[0,\" Back\"]],\"parameters\":[]},null],[0,\"\\n    \"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"application\",\"canConfigDevice\"]]],null,{\"statements\":[[0,\"      \"],[6,\"button\"],[9,\"class\",\"btn btn-default\"],[3,\"action\",[[19,0,[]],\"configure\"]],[7],[0,\"Configuration request\"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "vts-ember/templates/device-config.hbs"
    }
  });

  _exports.default = _default;
});