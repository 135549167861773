define("vts-ember/routes/users/new", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      return this.store.findAll('project');
    },
    setupController: function setupController(controller, projects) {
      controller.stopEditing();
      controller.startEditing();
      controller.set('projects', projects);
      var pid = this.controllerFor('application').get('model');
      var project = null;

      if (pid && pid != '000') {
        project = this.store.peekRecord('project', pid);
      } else {
        project = projects.objectAt(0);
      }

      controller.set('selectedProject', project);
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      var app = Ember.getOwner(this).lookup('controller:application');

      if (!app.get('canAddUsers')) {
        this.transitionTo('application');
      }
    }
  });

  _exports.default = _default;
});