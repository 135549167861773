define("vts-ember/controllers/projects/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ProjectsEditController = Ember.Controller.extend({
    editing: false,
    videoFormatOptions: [{
      label: 'mov',
      value: 'mov'
    }, {
      label: 'mp4',
      value: 'mp4'
    }],
    platformOptions: [{
      label: 'VTS',
      value: 'VTS'
    }, {
      label: 'Key View',
      value: 'VIEW'
    }, {
      label: 'Key View Pro',
      value: 'VIEW_PRO'
    }, {
      label: 'Camtrak',
      value: 'Camtrak'
    }, {
      label: 'Geotab',
      value: 'Geotab'
    }, {
      label: 'Geotab Pro',
      value: 'Geotab_Pro'
    }, {
      label: 'SureCam View',
      value: 'SURECAM_VIEW'
    }, {
      label: 'SureCam View Pro',
      value: 'SURECAM_VIEW_PRO'
    }],
    ownerOptions: [{
      label: 'n/a',
      value: ''
    }, {
      label: 'US',
      value: 'US'
    }, {
      label: 'UK',
      value: 'UK'
    }, {
      label: 'Product',
      value: 'Product'
    }, {
      label: 'Stores',
      value: 'Stores'
    }],
    startEditing: function startEditing() {
      this.stopEditing();
      this.editing = true;
    },
    stopEditing: function stopEditing() {
      if (this.editing) {
        this.get('model').rollbackAttributes();
        this.editing = false;
      }
    },
    actions: {
      save: function save() {
        if (!this.get('model.validationError')) {
          var _this = this;

          this.editing = false;
          this.get('model').save().then(function () {
            _this.transitionToRoute('projects');
          });
        }
      },
      cancel: function cancel() {
        this.stopEditing();
        this.transitionToRoute('projects');
      }
    }
  });
  var _default = ProjectsEditController;
  _exports.default = _default;
});