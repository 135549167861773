define("vts-ember/controllers/vehicle-statistics", ["exports", "vts-ember/controllers/tables"], function (_exports, _tables) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var VehicleStatisticsController = _tables.default.extend({
    application: Ember.inject.controller(),
    queryParams: ['page', 'recordsPerPage', 'sortWith', 'order', 'range'],
    sortBy: 'start',
    sortAscending: false,
    rangeOptions: [1, 7, 30],
    range: 7,
    sortWith: 'timestamp',
    order: 'desc',
    icon: 'icon-chevron-down',
    unit: 'km',
    actions: {}
  });

  var _default = VehicleStatisticsController;
  _exports.default = _default;
});