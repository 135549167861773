define("vts-ember/models/accel", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Accel = _emberData.default.Model.extend({
    timestamp: _emberData.default.attr('date'),
    acceleration: _emberData.default.attr('number'),
    xAccel: _emberData.default.attr('number'),
    yAccel: _emberData.default.attr('number'),
    zAccel: _emberData.default.attr('number') // y:         DS.attr('number'),
    // z:         DS.attr('number'),
    // device: DS.belongsTo('device')

  });

  var _default = Accel;
  _exports.default = _default;
});