define("vts-ember/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var User = _emberData.default.Model.extend({
    email: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    alertEmail: _emberData.default.attr('string'),
    alertable: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    driverFacingPermission: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    forwardFacingPermission: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    name: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    projectId: _emberData.default.attr('string'),
    // projectName:   DS.attr('string'),
    consumerPids: _emberData.default.hasMany('project', {
      async: true
    }),
    role: _emberData.default.attr('string', {
      defaultValue: 'user'
    }),
    provider: _emberData.default.attr('string', {
      defaultValue: 'internal'
    }),
    devices: _emberData.default.hasMany('device', {
      async: true
    }),
    activated: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    videoRequestPermission: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    liveCheckPermission: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isSelected: false,
    alertEmailClean: function () {
      var pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i;
      var alertEmail = this.get('alertEmail');

      if (alertEmail != null) {
        if (alertEmail.match(pattern) == null) {
          return 'Email must be a valid email address!';
        }
      }

      return '';
    }.property('alertEmail'),
    emailClean: function () {
      var pattern = /^[a-zA-Z0-9-_&$%!]+$/i;
      var username = this.get('email');

      if (username != null) {
        if (username.match(pattern) == null) {
          return 'Username can contain only alphanumeric and -_&$%! characters';
        }
      }

      return '';
    }.property('email'),
    passwordClean: function () {
      var pattern = /^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d).*$/;
      var password = this.get('password');

      if (password != null) {
        if (password.match(pattern) == null) {
          return 'Password must contain 8 characters and at least one number and one letter';
        }
      }

      return '';
    }.property('password'),
    passwordConfirmation: _emberData.default.attr('string'),
    passwordConfirmationClean: function () {
      if (this.get('passwordConfirmation') != this.get('password')) {
        return 'Password confirmation does not match the given password';
      }

      return '';
    }.property('passwordConfirmation'),
    nameClean: function () {
      var pattern = /^[a-zA-Z0-9 ]+$/i;
      var emptyPattern = /^$|\s+/;
      var name = this.get('name');

      if (name != null) {
        if (name.match(pattern) == null || name == ' ') {
          return 'Name must be a combination of only alphanumeric characters and spaces';
        }
      }

      return '';
    }.property('name'),
    validationError: function () {
      if (this.get('emailClean') == '' && this.get('passwordClean') == '' && this.get('passwordConfirmationClean') == '' && this.get('nameClean') == '' && this.get('email') != null && this.get('name') != null && this.get('alertEmailClean') == '') {
        return false;
      } else {
        return true;
      }
    }.property('emailClean', 'passwordClean', 'passwordConfirmationClean', 'nameClean', 'email', 'password', 'passwordConfirmation', 'name', 'alertEmailClean'),
    isAdmin: function () {
      if (this.get('role') == 'admin') {
        return true;
      } else {
        return false;
      }
    }.property('role'),
    isAnyAdmin: function () {
      return this.get('role') == 'admin' || this.get('role') == 'project admin';
    }.property('role'),
    canAccessVideoRequests: function () {
      return this.get('role') == 'admin' || this.get('videoRequestPermission');
    }.property('role', 'videoRequestPermission'),
    canAccessLiveChecks: function () {
      return this.get('role') == 'admin' || this.get('liveCheckPermission');
    }.property('role'),
    canAccessVideoRequestsOrLiveChecks: function () {
      return this.get('canAccessVideoRequests') || this.get('canAccessLiveChecks');
    }.property('canAccessVideoRequests', 'canAccessLiveChecks'),
    country: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    temperature: _emberData.default.attr('string')
  });

  var _default = User;
  _exports.default = _default;
});