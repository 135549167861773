define("vts-ember/controllers/video-request/trip/preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    cameraOptions: [{
      label: 'Camera 1',
      value: 1
    }, {
      label: 'Camera 2',
      value: 2
    }],
    previewLengthMap: {
      60: '1 minute',
      180: '3 minutes',
      300: '5 minutes',
      600: '10 minutes',
      1200: '20 minutes'
    },
    range: {
      'min': 60,
      '10%': 180,
      '20%': 300,
      '30%': 600,
      'max': 1200
    },
    previewLength: 60,
    previewCamera: 1,
    previewLengthFormatted: function () {
      return this.get('previewLengthMap')[this.get('previewLength')];
    }.property('previewLength'),
    start: null,
    startString: function () {
      var start = this.get('start');

      if (start) {
        return this.get('start').toString();
      } else {
        return '';
      }
    }.property('start'),
    format: {
      to: function to(value) {
        // minutes and seconds
        var mins = ~~(duration % 3600 / 60);
        var secs = ~~duration % 60;
        ret = "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        return ret;
      },
      from: function from(value) {
        return +value;
      }
    },
    actions: {
      close: function close() {
        this.transitionToRoute('video-request.trip');
      },
      startTimeChanged: function startTimeChanged(startTime) {
        var tm = new Date(startTime);
        var tripStart = new Date(this.get('model.start'));
        var tripStop = new Date(this.get('model.stop'));

        if (tm > tripStop) {
          tm = tripStop;
        }

        if (tm < tripStart) {
          tm = tripStart;
        }

        this.set('start', tm);
      },
      changedAction: function changedAction(a) {
        this.set('previewLength', parseInt(a));
      },
      trackClicked: function trackClicked(startDate) {
        this.set('start', startDate);
      },
      requestPreview: function requestPreview() {
        var ts = this.get('start');
        var length = this.get('previewLength');
        var camera = this.get('previewCamera');
        var step = 5000;

        if (ts == null) {
          window.alert('Please select a startpoint for the video preview request by clicking on the map.');
          return;
        }

        if (window.confirm('In order to submit a Preview Request, the device must be active. ' + 'A Preview Request for an active device will be submitted, and begin uploading immediately. ' + 'A Preview Request for an inactive device will be submitted, and queued for upload immediately ' + 'upon the device powering on. ' + 'For an inactive device that requires an immediate Preview Request, please turn on the vehicle’s ignition.')) {
          var _this = this;

          var xhttp = new XMLHttpRequest();

          xhttp.onreadystatechange = function () {
            if (xhttp.readyState === 4 && xhttp.status === 200) {
              window.alert('Video preview request successful, footage will be transfered when the device is online.');

              _this.transitionToRoute('video-request.trip');
            }
          };

          xhttp.open('POST', '/videos', true);
          xhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
          this.get('session').authorize('authorizer:devise', function (headerName, headerValue) {
            var headers = {};
            headers[headerName] = headerValue;
            xhttp.setRequestHeader(headerName, headerValue);
          });
          var serial = this.get('model.device.serial');
          xhttp.send('device_id=' + serial + '&start=' + ts.toISOString() + '&length=' + length + '&camera=' + camera + '&step=' + step);
        }
      }
    }
  });

  _exports.default = _default;
});