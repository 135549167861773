define("vts-ember/controllers/video-request/new", ["exports", "vts-ember/controllers/tables"], function (_exports, _tables) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var VideoRequestNewController = _tables.default.extend({
    application: Ember.inject.controller(),
    currentUser: Ember.inject.service(),
    projectId: null,
    search: null,
    sortBy: 'stateTooltip',
    sortAscending: false,
    filterParams: ['serial', 'fwVersion', 'fwBuild', 'licensePlate', 'zone', 'homeDepot', 'projectId', 'stateTooltip'],
    allSelected: false,
    isEditable: false,
    url: function () {
      return this.get('target').url;
    }.property('target'),
    actions: {
      /* ugly bunch of functions used to display search direction arrows on the correct column only */
      sortBySerial: function () {
        return this.get('sortBy') == 'serial';
      }.property('sortBy'),
      sortByFwBuild: function () {
        return this.get('sortBy') == 'fwBuild';
      }.property('sortBy'),
      sortByLicensePlate: function () {
        return this.get('sortBy') == 'licensePlate';
      }.property('sortBy'),
      sortByZone: function () {
        return this.get('sortBy') == 'zone';
      }.property('sortBy'),
      sortByProjectId: function () {
        return this.get('sortBy') == 'projectId';
      }.property('sortBy'),
      sortByStateTooltip: function () {
        return this.get('sortBy') == 'stateTooltip';
      }.property('sortBy')
    }
  });

  var _default = VideoRequestNewController;
  _exports.default = _default;
});