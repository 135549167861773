define("vts-ember/controllers/project/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ProjectIndexController = Ember.Controller.extend({
    application: Ember.inject.controller(),
    devices: Ember.inject.controller(),
    isEditing: false,
    videoFormatOptions: [{
      label: 'mov',
      value: 'mov'
    }, {
      label: 'mp4',
      value: 'mp4'
    }],
    platformOptions: [{
      label: 'VTS',
      value: 'VTS'
    }, {
      label: 'Key View',
      value: 'VIEW'
    }, {
      label: 'Key View Pro',
      value: 'VIEW_PRO'
    }, {
      label: 'Camtrak',
      value: 'Camtrak'
    }, {
      label: 'Geotab',
      value: 'Geotab'
    }, {
      label: 'Geotab Pro',
      value: 'Geotab_Pro'
    }, {
      label: 'SureCam View',
      value: 'SURECAM_VIEW'
    }, {
      label: 'SureCam View Pro',
      value: 'SURECAM_VIEW_PRO'
    }],
    ownerOptions: [{
      label: 'n/a',
      value: ''
    }, {
      label: 'US',
      value: 'US'
    }, {
      label: 'UK',
      value: 'UK'
    }, {
      label: 'Product',
      value: 'Product'
    }, {
      label: 'Stores',
      value: 'Stores'
    }],
    stopEditing: function stopEditing() {
      this.get('model').rollbackAttributes();
      this.set('isEditing', false);
    },
    actions: {
      startEditing: function startEditing() {
        this.set('isEditing', true);
      },
      save: function save() {
        if (!this.get('model.validationError')) {
          var _this = this;

          this.set('isEditing', false);
          this.get('model').save().then(function () {// return _this.send('exit');
            // _this.transitionToRoute('projects');
          });
        }
      },
      back: function back() {
        this.stopEditing();
        this.transitionToRoute('projects');
      },
      cancel: function cancel() {
        this.stopEditing();
      }
    }
  });
  var _default = ProjectIndexController;
  _exports.default = _default;
});