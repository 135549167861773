define("vts-ember/routes/device", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      return this.store.findRecord('device', params.device_id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('isEditing', false);
      var projects = this.store.peekAll('project'); //.filterBy('isTempOrAdmin', false)

      if (projects.get('length') == 0) {
        projects = this.store.findAll('project');
      }

      var project = this.store.findRecord('project', model.get('projectId'));
      controller.set('projects', projects);
      controller.set('project', project);
      controller.get('history').set('model', this.store.query('deviceHistoryLog', {
        device_id: model.get('id')
      }));
    }
  });

  _exports.default = _default;
});