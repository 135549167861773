define("vts-ember/controllers/users/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UsersUserController = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    application: Ember.inject.controller(),
    users: Ember.inject.controller(),
    isEditing: false,
    isEditable: false,
    selectedDevice: null,
    selectedProject: null,
    selectedRole: null,
    rolesOptions: ['project admin', 'user'],
    projects: null,
    chooseDevices: true,
    isNew: false,
    isNotNew: true,
    devs: Ember.computed('model.devices', function () {
      var devices = this.get('model.devices');

      if (devices) {
        return devices.toArray();
      }

      return null;
    }),
    setBySearchable: null,
    ssoProviderOptions: [{
      label: 'internal',
      value: ''
    }, {
      label: 'Masternaut Connect',
      value: 'connect'
    }],

    /* properties used in usersController template */
    isCurrentUser: function () {
      var email = this.get('currentUser.user.email');
      return this.get('email') == email;
    }.property('model.email'),
    canDelete: function () {
      var role = this.get('currentUser.user.role');

      if (role == 'project admin') {
        if (this.get('role') == 'admin') {
          return false;
        }
      }

      if (this.get('email') == this.get('currentUser.user.email')) {
        return false;
      }

      return true;
    }.property('model.email'),

    /* ---- */
    startEditing: function startEditing() {
      this.stopEditing();
    },
    stopEditing: function stopEditing() {
      this.get('model').rollbackAttributes();
    },
    destroyRecord: function destroyRecord() {
      var _this = this;

      if (window.confirm('Are you sure you want to delete this user?')) {
        var user = this.get('model');
        user.deleteRecord();
        user.save().then(function () {
          _this.transitionTo('users.index');
        });
      }
    },
    actions: {
      addDevice: function addDevice() {
        var devices = this.get('model.devices');
        var id = this.get('selectedDevice');
        this.store.findRecord('device', id).then(function (device) {
          devices.pushObject(device);
        });
      },
      updateSelectedDevice: function updateSelectedDevice(device) {
        this.set('selectedDevice', device.id);
      },
      updateSelectedDevices: function updateSelectedDevices(item) {
        this.set('model.devices', item);
      },
      removeDevice: function removeDevice(device) {
        this.get('model.devices').removeObject(device);
      },
      save: function save() {
        if (!this.get('model.validationError')) {
          var user = this.get('model');

          var _this = this;

          user.set('projectId', this.selectedProject);
          user.save().then(function () {
            _this.transitionToRoute('users.index');
          });
        }
      },
      refresh: function refresh() {
        var router = this.get('target');
        router.handleURL(router.get('url'));
      },
      edit: function edit() {
        this.set('isEditing', true);
      },
      cancel: function cancel() {
        this.stopEditing();
        this.transitionToRoute('users.index');
      }
    }
  });
  var _default = UsersUserController;
  _exports.default = _default;
});