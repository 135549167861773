define("vts-ember/components/button-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ButtonGroupComponent = Ember.Component.extend({
    tagName: 'button',
    type: 'button',
    selection: null,
    value: null,
    attributeBindings: ['name', 'type', 'value', 'checked:checked'],
    classNames: ['btn'],
    classNameBindings: ['checked:active'],
    click: function click() {
      this.set('selection', this.get('value'));
    },
    checked: function () {
      return this.get('value') === this.get('selection');
    }.property('selection')
  });
  var _default = ButtonGroupComponent;
  _exports.default = _default;
});