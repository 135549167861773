define("vts-ember/controllers/video-request/complete", ["exports", "vts-ember/controllers/tables"], function (_exports, _tables) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var VideoRequestCompleteController = _tables.default.extend({
    application: Ember.inject.controller(),
    queryParams: ['recordsPerPage'],
    recordsPerPage: 1000,
    actions: {}
  });

  var _default = VideoRequestCompleteController;
  _exports.default = _default;
});