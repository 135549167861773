define("vts-ember/routes/video-request/trips", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),
    queryParams: {
      currentPage: {
        refreshModel: true
      },
      recordsPerPage: {
        refreshModel: true
      } // startAt: { refreshModel: true },
      // endAt: { refreshModel: true }

    },

    /*
     * Get Trips to list for selected devices if available
     * else list all trips
     */
    model: function model(params) {
      this.store.query('trip', params);
      return Ember.RSVP.hash({
        trips: this.store.query('trip', params),
        device: this.store.findRecord('device', params.device_id)
      });
    },
    setupController: function setupController(controller, models) {
      controller.set('device', models.device);

      this._super(controller, models.trips);

      controller.set('recordsNo', models.trips.get('meta').total);
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (!this.get('currentUser.user.canAccessVideoRequests')) {
        this.transitionTo('application');
      }
    }
  });

  _exports.default = _default;
});