define("vts-ember/controllers/trip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TripController = Ember.Controller.extend({
    application: Ember.inject.controller(),
    devices: Ember.inject.controller(),
    session: Ember.inject.service(),
    projects: null,
    videos: null,
    unit: 'km',
    requestTime: '0:0:0',
    requestLengthOptions: ['30 seconds', '1 minute', '2 minutes', '5 minutes', '10 minutes'],
    lengthMapping: {
      '30 seconds': 30,
      '1 minute': 60,
      '2 minutes': 120,
      '5 minutes': 300,
      '10 minutes': 600
    },
    requestLength: '30 seconds',
    requestCamera: 'Camera 1',
    requestCameraOptions: ['Camera 1', 'Camera 2', 'Camera 3', 'Camera 4'],
    cameraMapping: {
      'Camera 1': 1,
      'Camera 2': 2,
      'Camera 3': 3,
      'Camera 4': 4
    },
    actions: {
      request: function request(video) {
        if (window.confirm('Are you sure you want to request a video upload over mobile network?')) {
          video.set('state', 'requested');
          video.save();
        }
      },
      requestVideo: function requestVideo() {
        var ts = new Date(this.get('requestTime'));
        var start = this.get('model.start');
        start.setMilliseconds(0);
        var length = this.get('lengthMapping')[this.get('requestLength')];
        var camera = this.get('cameraMapping')[this.get('requestCamera')];

        if (ts < start) {
          window.alert('Selected time is before trip start time. ' + ts);
        } else if (window.confirm('Are you sure you want to request a video upload over mobile network?')) {
          var xhttp = new XMLHttpRequest();

          xhttp.onreadystatechange = function () {
            if (xhttp.readyState === 4 && xhttp.status === 200) {
              // console.log(xhttp.responseText)
              window.alert('Video request successful, footage will be transfered when the device is online.');
            }
          };

          xhttp.open('POST', '/videos', true);
          xhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
          this.get('session').authorize('authorizer:devise', function (headerName, headerValue) {
            var headers = {};
            headers[headerName] = headerValue;
            xhttp.setRequestHeader(headerName, headerValue);
          });
          var serial = this.get('model.device.serial');
          xhttp.send('device_id=' + serial + '&start=' + ts.toISOString() + '&length=' + length + '&camera=' + camera + '&split=60');
        }
      },
      requestTimeChanged: function requestTimeChanged(requestTime) {
        if (requestTime) {
          this.set('requestTime', requestTime);
        }
      },
      refresh: function refresh() {
        this.set('videos', this.get('store').query('video', {
          device: this.get('model.device.id'),
          start: this.get('model.start'),
          stop: this.get('model.stop'),
          recordsPerPage: 100
        }));
      }
    }
  });
  var _default = TripController;
  _exports.default = _default;
});