define("vts-ember/templates/components/simple-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WRLoDx7h",
    "block": "{\"symbols\":[\"sb\",\"&default\",\"@on-select\",\"@value\"],\"statements\":[[4,\"select-box/native\",null,[[\"class\",\"value\",\"on-focus-in\",\"on-focus-out\",\"on-update\",\"on-select\"],[\"form-control\",[19,4,[]],[25,\"action\",[[19,0,[]],\"focusIn\"],null],[25,\"action\",[[19,0,[]],\"focusOut\"],null],[25,\"action\",[[19,0,[]],\"updateDisplayLabel\"],null],[19,3,[]]]],{\"statements\":[[0,\"  \"],[11,2,[[19,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "vts-ember/templates/components/simple-select.hbs"
    }
  });

  _exports.default = _default;
});