define("vts-ember/templates/components/video-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gpk3L4sG",
    "block": "{\"symbols\":[],\"statements\":[[1,[18,\"bs-button-group\"],false],[0,\"\\n\"],[4,\"if\",[[20,[\"video\",\"isDownloadable\"]]],null,{\"statements\":[[0,\"  \"],[6,\"button\"],[9,\"type\",\"button\"],[9,\"class\",\"btn btn-default\"],[3,\"action\",[[19,0,[]],\"download\"]],[7],[6,\"i\"],[9,\"class\",\"glyphicon glyphicon-download\"],[7],[8],[0,\" Download\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[20,[\"video\",\"isAvailable\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"bs-button\",null,[[\"defaultText\",\"icon\",\"onClick\"],[\"Upload to server\",\"glyphicon glyphicon-upload\",[25,\"action\",[[19,0,[]],\"request\",[20,[\"video\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[4,\"if\",[[20,[\"video\",\"isComplete\"]]],null,{\"statements\":[[4,\"link-to\",[\"video\",[20,[\"video\"]]],null,{\"statements\":[[0,\"    \"],[6,\"button\"],[9,\"class\",\"btn btn-default\"],[7],[0,\"\\n      \"],[6,\"span\"],[9,\"class\",\"glyphicon glyphicon-play\"],[9,\"aria-hidden\",\"true\"],[7],[8],[0,\" View\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "vts-ember/templates/components/video-link.hbs"
    }
  });

  _exports.default = _default;
});