define("vts-ember/models/incident", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Incident = _emberData.default.Model.extend({
    timestamp: _emberData.default.attr('date'),
    timestampFormatted: function () {
      if (this.get('timestamp') != undefined) {
        return (0, _moment.default)(this.get('timestamp')).format('yyyy-MM-DD H:mm:ss');
      }

      return null;
    }.property('timestamp'),
    type: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    device: _emberData.default.belongsTo('device', {
      async: true
    }),
    incidentsCount: _emberData.default.attr('number'),
    state: _emberData.default.attr('string'),
    gpsData: null,
    accelData: null,
    videos: null,
    notSeen: function () {
      if (this.get('state') == 'seen') {
        return false;
      } else {
        return true;
      }
    }.property('state')
  });

  var _default = Incident;
  _exports.default = _default;
});