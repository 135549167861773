define("vts-ember/controllers/incident", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    devices: Ember.inject.controller(),
    gpsData: null,
    accelData: null,
    videos: null,
    accelDataX: null,
    accelDataY: null,
    accelDataZ: null,
    timestamps: null,
    chartoptions: {
      responsive: true,
      interaction: {
        intersect: false
      },
      elements: {
        point: {
          radius: function customRadius(context) {
            var index = context.dataIndex;
            var incidentIndex = context.chart.options.incidentIndex;
            return index === incidentIndex ? 10 : 2;
          },
          display: true
        }
      },
      scales: {
        yAxes: [{
          ticks: {
            min: -2,
            max: 2
          }
        }]
      }
    },
    accel: Ember.computed('accelDataX', 'accelDataY', 'accelDataZ', function () {
      var timestamps = this.get('timestamps');
      return {
        labels: timestamps,
        datasets: [{
          label: 'X Acceleration (+) / Braking (-)',
          data: this.get('accelDataX'),
          borderColor: "#3cba9f",
          backgroundColor: "#71d1bd",
          fill: false
        }, {
          label: 'Y - LH Corner (+) / RH Corner (-)',
          data: this.get('accelDataY'),
          borderColor: "#3e95cd",
          backgroundColor: "#7bb6dd",
          fill: false
        }, {
          label: 'Z - Vertical (+/-)',
          data: this.get('accelDataZ'),
          borderColor: "#c45850",
          backgroundColor: "#d78f89",
          fill: false
        }]
      };
    }),
    actions: {
      request: function request(video) {
        if (window.confirm('Are you sure you want to request a video upload over mobile network?')) {
          video.set('state', 'requested');
          video.save();
        }
      }
    }
  });

  _exports.default = _default;
});