define("vts-ember/services/project", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ProjectService = Ember.Service.extend({
    project: null,
    id: null,
    setSelected: function setSelected(project) {
      this.set('project', project);
    },
    setId: function setId(id) {
      this.set('id', id);
    }
  });
  var _default = ProjectService;
  _exports.default = _default;
});