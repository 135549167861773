define("vts-ember/templates/components/login-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H6fZYN8g",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"container\"],[7],[0,\"\\n  \"],[6,\"form\"],[9,\"class\",\"form-signin\"],[3,\"action\",[[19,0,[]],\"authenticate\"],[[\"on\"],[\"submit\"]]],[7],[0,\"\\n    \"],[6,\"h2\"],[9,\"class\",\"form-signin-heading\"],[7],[0,\"Please sign in\"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"form-group\"],[7],[0,\"\\n      \"],[6,\"label\"],[9,\"for\",\"identification\"],[9,\"class\",\"sr-only\"],[7],[0,\"Login\"],[8],[0,\"\\n      \"],[1,[25,\"input\",null,[[\"id\",\"placeholder\",\"value\",\"class\"],[\"identification\",\"Enter Username\",[20,[\"identification\"]],\"form-control\"]]],false],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"form-group\"],[7],[0,\"\\n      \"],[6,\"label\"],[9,\"for\",\"password\"],[9,\"class\",\"sr-only\"],[7],[0,\"Password\"],[8],[0,\"\\n      \"],[1,[25,\"input\",null,[[\"id\",\"placeholder\",\"type\",\"value\",\"class\"],[\"password\",\"Enter Password\",\"password\",[20,[\"password\"]],\"form-control\"]]],false],[0,\"\\n    \"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"errorMessage\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"alert-danger alert\"],[7],[0,\"\\n        \"],[1,[18,\"errorMessage\"],false],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[6,\"div\"],[9,\"class\",\"form-group\"],[7],[0,\"\\n      \"],[6,\"a\"],[9,\"href\",\"/password_resets/new\"],[9,\"target\",\"_blank\"],[7],[0,\"Forgot password?\"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"button\"],[9,\"type\",\"submit\"],[9,\"class\",\"btn btn-lg btn-primary \"],[7],[0,\"Sign in\"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "vts-ember/templates/components/login-form.hbs"
    }
  });

  _exports.default = _default;
});