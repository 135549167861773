define("vts-ember/routes/projects/edit", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    setupController: function setupController(controller, model) {
      controller.startEditing();
    },
    deactivate: function deactivate() {
      // reset editing state
      var controller = this.controllerFor('projects.edit');
      controller.stopEditing();
    }
  });

  _exports.default = _default;
});