define("vts-ember/components/video-player", ["exports", "plyr"], function (_exports, _plyr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    player: null,
    currentTime: null,
    updatePlayer: function () {
      var offset = this.get('offset');

      if (offset) {
        this.get('player').currentTime = offset / 1000;
      }
    }.observes('offset'),
    didInsertElement: function didInsertElement() {
      var _this = this;

      var player = new _plyr.default('#player', {
        "title": "VTS",
        "volume": 0.8
      });
      this.set('player', player);
      player.on('loadedmetadata', function (event) {
        var player = event.detail.plyr;

        if (player.source.includes('preview')) {
          player.speed = 10;
        } else {
          player.speed = 1;
        }
      });
      player.on('timeupdate', function (event) {
        var player = event.detail.plyr;

        _this.get('onTimeUpdate')(player.currentTime);
      });
    }
  });

  _exports.default = _default;
});