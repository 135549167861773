define("vts-ember/controllers/device", ["exports", "vts-ember/controllers/tables"], function (_exports, _tables) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DeviceController = Ember.Controller.extend({
    isEditing: false,
    application: Ember.inject.controller(),
    devices: Ember.inject.controller(),
    // project: Ember.inject.service(),
    // settings: Ember.inject.controller(),
    isNew: false,
    history: _tables.default.create({
      sortBy: 'time'
    }),
    desc1: '',
    desc2: '',
    desc3: '',
    desc4: '',
    vehicleTypes: [{
      desc: 'Undefined',
      id: 0
    }, {
      desc: 'Car',
      id: 1
    }, {
      desc: 'Car derived van',
      id: 2
    }, {
      desc: 'LCV less than 3.5 tonnes',
      id: 3
    }, {
      desc: 'LCV more than 3.5 tonnes',
      id: 4
    }, {
      desc: 'HGV',
      id: 5
    }],
    vehicleTypeDesc: function () {
      var types = this.get('vehicleTypes');
      var id = this.get('model.vehicleType');
      return types[id].desc;
    }.property('model.vehicleType'),
    additionalHardwareMap: [{
      desc: 'Undefined',
      id: 0
    }, {
      desc: 'MDVR',
      id: 1
    }, {
      desc: 'Connected MDVR',
      id: 2
    }],
    additionalHardwareDesc: function () {
      var map = this.get('additionalHardwareMap');
      var id = this.get('model.additionalHardware');
      return map[id].desc;
    }.property('model.additionalHardware'),
    descString: function () {
      var desc = '';
      var d = this.get('model.description');

      if (d != '' && d != null) {
        var descArray = eval(d);
        descArray.forEach(function (delem) {
          return desc += delem + ' ';
        });
      }

      return desc;
    }.property('model.description'),
    stopEditing: function stopEditing() {
      this.get('model').rollbackAttributes();
      this.set('isEditing', false);
    },
    projectsSorted: function () {
      var projects = this.get('projects');

      if (projects) {
        return projects.toArray().sort(function (a, b) {
          var pa = a.get('projectId');
          var pb = b.get('projectId');
          if (pa < pb) return -1;
          if (pa > pb) return 1;
          return 0;
        });
      } else {
        return null;
      }
    }.property('projects'),
    canFormatSystem: function () {
      return this.get('model').get('fwBuild') > '1710010000';
    }.property('fwBuild'),
    updateDisabled: function () {
      return !this.get('model.hasDirtyAttributes') && this.get('updatedDescription') == this.get('model.description');
    }.property('model.hasDirtyAttributes', 'updatedDescription'),
    updatedDescription: function () {
      var desc = [];

      if (this.get('desc1') != '' && this.get('desc1') != null) {
        desc.push(this.get('desc1'));
      }

      if (this.get('desc2') != '' && this.get('desc2') != null) {
        desc.push(this.get('desc2'));
      }

      if (this.get('desc3') != '' && this.get('desc3') != null) {
        desc.push(this.get('desc3'));
      }

      if (this.get('desc4') != '' && this.get('desc4') != null) {
        desc.push(this.get('desc4'));
      }

      return JSON.stringify(desc);
    }.property('desc1', 'desc2', 'desc3', 'desc4'),
    initDescription: function initDescription() {
      console.log('initDescription');
      var d = this.get('model.description');

      if (d != '' && d != null) {
        var desc = eval(d);
        this.set('desc1', desc[0]);
        this.set('desc2', desc[1]);
        this.set('desc3', desc[2]);
        this.set('desc4', desc[3]);
      } else {
        this.set('desc1', '');
        this.set('desc2', '');
        this.set('desc3', '');
        this.set('desc4', '');
      }
    },
    actions: {
      startEditing: function startEditing() {
        this.initDescription();
        this.stopEditing();
        this.set('isEditing', true);
      },
      destroyRecord: function destroyRecord() {
        if (window.confirm('Are you sure you want to delete this device?')) {
          var device = this.get('model');
          device.deleteRecord(); // TODO: error handling

          device.save().then(function () {
            this.transitionToRoute('devices.index');
          }); // var recsNo = this.get('controllers.devices.recordsNo') - 1
          // this.set('controllers.devices.recordsNo', recsNo)
        }
      },
      save: function save() {
        if (!this.get('model.validationError')) {
          var device = this.get('model');
          device.set('description', this.get('updatedDescription'));
          device.save();
          this.stopEditing();
        }
      },
      cancel: function cancel() {
        this.stopEditing();
      },
      updateProject: function updateProject(project) {
        this.set('model.projectId', project.get('projectId'));
      },
      configure: function configure() {
        var config = null;
        var device = this.get('model');

        var _this = this;

        this.stopEditing(); // var config = this.store.query('device_config', device.get('id')).then(function () {
        //   _this.transitionToRoute('deviceConfig', config)
        // }, function(resp) {
        //   console.log("Failed to load device config " + resp)
        // })

        this.transitionToRoute('device-config', device.get('id'));
      },
      cancelPendingUploads: function cancelPendingUploads() {
        if (window.confirm('Are you sure you want to cancel all pending video uploads?')) {
          var device = this.get('model');
          var command = this.store.createRecord('command', {
            device_id: device.get('serial'),
            command: 'cancel_upload'
          });
          command.save();
        }
      },
      clearAlertStatus: function clearAlertStatus() {
        if (window.confirm('All incidents will be marked as read. Are you sure you want to clear the alert status?')) {
          var device = this.get('model');
          var command = this.store.createRecord('command', {
            device_id: device.get('id'),
            command: 'clear_alert_status'
          });
          command.save();
        }
      },
      formatCard: function formatCard() {
        if (window.confirm('All data on the SD card will be deleted on the next reboot. Are you sure you want to send an SD card reformat request?')) {
          var device = this.get('model');
          var command = this.store.createRecord('command', {
            device_id: device.get('serial'),
            command: 'format_card'
          });
          command.save();
        }
      },
      formatSystem: function formatSystem() {
        if (window.confirm('All data on the device will be deleted on the next reboot. Are you sure you want to send a flash reformat request?')) {
          var device = this.get('model');
          var command = this.store.createRecord('command', {
            device_id: device.get('serial'),
            command: 'format_system'
          });
          command.save();
        }
      },
      openLiveCheck: function openLiveCheck() {
        this.transitionToRoute('live-check', this.get('model'));
      },
      // warning this is a hack
      // i think this function should be encapsulated in the
      // controller device history controller
      // the form is not nicely cleared here
      // and the input elements are not part of a form
      add_history: function add_history() {
        var action = $("#device-history-form .action input").val();
        var time = $("#device-history-form .time input").val();
        var device = this.get('model');
        console.log("form", action, time, device);
        var device_history_item = this.store.createRecord('deviceHistoryLog', {
          device: device,
          action: action,
          time: time
        }); // // there is not way to wait for this to complete right now.
        // transaction.commit();
        // the whole list is reloaded from the server because i could not
        // add the device_work_item from the transaction to the array controller

        $("#device-history-form .action input").val('');
        var device_history_logs = this.store.query('deviceHistoryLog', {
          device_id: this.get('model.id')
        });
        this.get('history').set('model', device_history_logs);
      },
      delete_history_item: function delete_history_item(historyItem) {
        var _this = this;

        if (window.confirm("Are you sure you want to delete the device history log item: " + historyItem.get('action') + "\n" + "from: " + historyItem.get('time'))) {
          historyItem.deleteRecord();
          historyItem.save().then(function () {
            var device_history = _this.get('history');

            var device_id = _this.get('model.id');

            var device_history_logs = _this.store.query('deviceHistoryLog', {
              device_id: device_id
            });

            device_history.set('model', device_history_logs);
          });
        }
      }
    }
  });
  var _default = DeviceController;
  _exports.default = _default;
});