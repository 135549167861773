define("vts-ember/components/device-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DeviceMapComponent = Ember.Component.extend({
    map: null,
    marker: null,
    timer: null,
    // timer for reloading gps data
    ms: 5000,
    // timer milli seconds
    oldGps: null,
    gpsChanged: function () {
      if (this.map != null) {
        var latitude = this.get('device.latitude');
        var longitude = this.get('device.longitude');
        var latlng = new google.maps.LatLng(latitude, longitude);
        var oldMarker = this.marker;
        var icon = '';
        var state = this.get('device.eventState');

        if (state == 'online') {
          icon = 'https://labs.google.com/ridefinder/images/mm_20_green.png';
        } else if (state === 'offline' || state === 'disconnected') {
          icon = 'https://labs.google.com/ridefinder/images/mm_20_blue.png';
        } else if (state == 'critical') {
          icon = 'https://labs.google.com/ridefinder/images/mm_20_red.png';
        } else if (state == 'harsh') {
          icon = 'https://labs.google.com/ridefinder/images/mm_20_yellow.png';
        } else {
          icon = 'https://labs.google.com/ridefinder/images/mm_20_gray.png';
        }

        this.marker = new google.maps.Marker({
          position: latlng,
          map: this.get('map'),
          icon: icon,
          optimized: false
        });
        this.map.setCenter(latlng);

        if (oldMarker) {
          oldMarker.setMap(null);
        }
      }
    }.observes('device.latitude'),
    reloadDevice: function reloadDevice() {
      if (this.map_view) {
        var device = this.map_view.get('device'); // make sure the device is not being edited or inFlight before reloading

        if (device.get('currentState.stateName') == 'root.loaded.saved') {
          device.reload();
        }
      }
    },
    didInsertElement: function didInsertElement() {
      var device = this.get('device');
      var mapOptions = {
        zoom: 10,
        maxZoom: 16,
        minZoom: 2,
        center: new google.maps.LatLng(75.271567, 10.54617628220852),
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
        },
        panControl: true,
        zoomControl: true,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.SMALL,
          position: google.maps.ControlPosition.TOP_LEFT
        },
        scaleControl: true,
        streetViewControl: false,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP
        },
        navigationControl: true,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        noClear: false,
        fullscreenControl: true
      };

      if (this.map) {
        delete this.map;
        this.map = null;
      }

      this.map = new google.maps.Map(this.$().get(0), mapOptions); //    this.$().css({ width: "660px", height: "600px" });

      this.$().css({
        width: '100%',
        height: '600px'
      });
      var trafficLayer = new google.maps.TrafficLayer();
      trafficLayer.setMap(this.map);
      google.maps.event.trigger(this.map, 'resize');
      this.gpsChanged();
      var map_view = this; // start timer to reloading gps data every ms milliseconds

      this.timer = window.setInterval(this.reloadDevice, this.get('ms'));
    },
    willDestroyElement: function willDestroyElement() {
      // stop gps timer
      if (this.timer) {
        window.clearInterval(this.timer);
        this.timer = null;
      }

      if (this.map) {
        var node = this.map.getDiv();

        if (node) {
          while (node.hasChildNodes()) {
            node.removeChild(node.firstChild);
          }
        }

        delete this.map;
        this.map = null;
      }
    }
  });
  var _default = DeviceMapComponent;
  _exports.default = _default;
});