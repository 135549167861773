define("vts-ember/models/device", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Device = _emberData.default.Model.extend({
    deviceConfig: _emberData.default.belongsTo('deviceConfig', {
      async: true
    }),
    driverDatum: _emberData.default.belongsTo('driverDatum', {
      async: true
    }),
    deviceStat: _emberData.default.belongsTo('deviceStat', {
      async: true
    }),
    lastConnected: _emberData.default.attr('date', {
      readOnly: true
    }),
    lastConnectedFormatted: function () {
      if (this.get('lastConnected') != undefined) {
        return (0, _moment.default)(this.get('lastConnected')).format('yyyy-MM-DD H:mm:ss');
      }

      return null;
    }.property('lastConnected'),
    lastConnectedDisplay: function () {
      var state = this.get('state');
      var lastConnected = this.get('lastConnected');

      if (state && lastConnected) {
        return state == 'unknown' ? '-' : lastConnected;
      }

      return '-';
    }.property('lastConnected', 'state'),
    firstConnected: _emberData.default.attr('date', {
      readOnly: true
    }),
    firstConnectedFormatted: function () {
      var fc = this.get('firstConnected');

      if (fc != undefined && fc.getFullYear() > 1970) {
        return fc;
      } else {
        return '-';
      }
    }.property('firstConnected'),
    footageStart: _emberData.default.attr('date', {
      readOnly: true
    }),
    footageStartFormatted: function () {
      var fc = this.get('footageStart');

      if (fc != undefined && fc.getFullYear() > 1970) {
        return fc;
      } else {
        return null;
      }
    }.property('footageStart'),
    audioAvailable: _emberData.default.attr('boolean'),
    serial: _emberData.default.attr('string'),
    cameras: _emberData.default.attr('number', {
      readOnly: true
    }),
    underfill: _emberData.default.attr('boolean', {
      readOnly: true
    }),
    // hwVersion:     DS.attr('string'),
    fwVersion: _emberData.default.attr('string', {
      readOnly: true
    }),
    fwBuild: _emberData.default.attr('string', {
      readOnly: true
    }),
    licensePlate: _emberData.default.attr('string'),
    zone: _emberData.default.attr('string'),
    additionalHardware: _emberData.default.attr('number'),
    vehicleType: _emberData.default.attr('number'),
    vehicleModel: _emberData.default.attr('string'),
    vehicleMake: _emberData.default.attr('string'),
    adminUcn: _emberData.default.attr('string'),
    gps: _emberData.default.belongsTo('gpsDatum'),
    projectId: _emberData.default.attr('string'),
    users: _emberData.default.hasMany('user', {
      inverse: null
    }),
    state: _emberData.default.attr('string', {
      readOnly: true
    }),
    operatingMode: _emberData.default.attr('string', {
      readOnly: true
    }),
    error: _emberData.default.attr('string', {
      readOnly: true
    }),
    errorTime: _emberData.default.attr('date', {
      readOnly: true
    }),
    errorTimeFormatted: function () {
      if (this.get('errorTime') != undefined) {
        return (0, _moment.default)(this.get('errorTime')).format('yyyy-MM-DD H:mm:ss');
      }

      return null;
    }.property('errorTime'),
    validErrorTime: function () {
      var y = new Date('2013-01-01');
      return this.get('errorTime') > y;
    }.property('errorTime'),
    stateTooltip: function () {
      var toolTip = this.get('onlineTooltip');

      if (this.get('eventState') === 'critical') {
        toolTip += ', incident';
      }

      if (this.get('eventState') === 'harsh') {
        toolTip += ', harsh';
      }

      return toolTip;
    }.property('onlineTooltip', 'eventState'),
    onlineTooltip: function () {
      var state = this.get('state'); // console.log('state.....', state);

      if (state === 'offline' || state === 'disconnected') {
        if (this.get('lastConnected') == null || this.get('lastConnected') == 'undefined') {
          return state;
        } else {
          return state + '\n' + this.get('lastConnected');
        }
      } else if (state === 'online') {
        return state;
      } else {
        return 'idle';
      }
    }.property('state', 'lastConnected'),
    onlineBtn: function () {
      var btnClass = 'btn btn-sm disabled ';

      if (this.get('state') === 'online') {
        btnClass += 'btn-success';
      } else {
        btnClass += 'btn-primary';
      }

      return btnClass;
    }.property('eventState'),
    eventState: _emberData.default.attr('string', {
      readOnly: true
    }),
    stateBtn: function () {
      var btnClass = 'btn btn-sm disabled ';

      if (this.get('eventState') === 'online') {
        btnClass += 'btn-success';
      } else if (this.get('eventState') === 'critical') {
        btnClass += 'btn-danger';
      } else if (this.get('eventState') === 'harsh') {
        btnClass += 'btn-warning';
      } else if (this.get('eventState') === 'disconnected' || this.get('eventState') === 'offline') {
        btnClass += 'btn-primary';
      } else {
        btnClass += 'btn-idle';
      }

      return btnClass;
    }.property('eventState'),
    rowClass: function () {
      var rowClass = '';

      if (this.get('eventState') === 'online') {
        rowClass += 'success';
      } else if (this.get('eventState') === 'critical') {
        rowClass += 'error';
      } else if (this.get('eventState') === 'harsh') {
        rowClass += 'warning';
      } else if (this.get('eventState') === 'disconnected') {
        rowClass += 'info';
      }

      return rowClass;
    }.property('eventState'),
    isDisconnected: function () {
      if (this.get('state') == 'disconnected') {
        return true;
      }

      return false;
    }.property('state'),
    hasError: function () {
      if (this.get('error') == 'none') {
        return false;
      }

      return true;
    }.property('error'),
    isSelected: false,
    // fields validations
    serialClean: function () {
      var pattern = /^[M\d]\d+$/i;
      var serial = this.get('serial');

      if (serial != null) {
        if (serial.match(pattern) == null || serial == ' ') {
          return 'Serial number invalid!';
        }
      }

      return '';
    }.property('serial'),
    serialLicense: function () {
      return this.get('serial') + ' ' + this.get('licensePlate');
    }.property('serial', 'licensePlate'),
    licensePlateClean: function () {
      var pattern = /^([a-zA-Z0-9 _-]+)$/i; // allows _ - and space

      var licensePlate = this.get('licensePlate');

      if (licensePlate != null) {
        if (licensePlate.match(pattern) == null || licensePlate == ' ') {
          return 'License plate name must be a combination of only alphanumerical characters, dashes, underscores and spaces!';
        }
      }

      return '';
    }.property('licensePlate'),
    validationError: function () {
      if (this.get('serialClean') == '' && this.get('serial') != null && this.get('projectId') != null) {
        return false;
      } else {
        return true;
      }
    }.property('serialClean', 'serial', 'projectId'),
    isTemp: function () {
      if (this.get('id') != null) {
        return false;
      } else {
        return true;
      }
    }.property('id'),
    driverName: _emberData.default.attr('string'),
    insurer: _emberData.default.attr('string'),
    policyNumber: _emberData.default.attr('string'),
    homeDepot: _emberData.default.attr('string'),
    imeiNumber: _emberData.default.attr('string'),
    fleetNumber: _emberData.default.attr('string'),
    sdcardCheck: _emberData.default.attr('string'),
    sdcardCheckDisplay: function () {
      var v = this.get('sdcardCheck');
      if (v != 'undefined') return v;else return null;
    }.property('sdcardCheck'),
    endtest: _emberData.default.attr('string'),
    endtestDisplay: function () {
      var v = this.get('endtest');
      if (v != 'undefined') return v;else return null;
    }.property('endtest'),
    gpsAntennaTest: _emberData.default.attr('string'),
    gpsAntennaTestDisplay: function () {
      var v = this.get('gpsAntennaTest');
      if (v != 'undefined') return v;else return null;
    }.property('gpsAntennaTest'),
    iccid: _emberData.default.attr('string', {
      readOnly: true
    }),
    description: _emberData.default.attr('string'),
    videoRequestCurrentUsage: _emberData.default.attr('number'),
    longitude: _emberData.default.attr('number', {
      readOnly: true
    }),
    latitude: _emberData.default.attr('number', {
      readOnly: true
    }),
    gps_available: function () {
      var latitude = this.get('latitude');
      var longitude = this.get('longitude');
      return latitude != 0 || longitude != 0;
    }.property('longitude', 'latitude'),
    mapToolTip: function () {
      var serial = this.get('serial') || '';
      var licensePlate = this.get('licensePlate');
      var driverName = this.get('driverName');
      var insurer = this.get('insurer');
      var policyNumber = this.get('policyNumber');
      var homeDepot = this.get('homeDepot');
      var displayInfo = 'Device: ' + serial;

      if (licensePlate && licensePlate != '') {
        displayInfo += '\n';
        displayInfo += 'License plate: ';
        displayInfo += licensePlate;
      }

      if (driverName && driverName != '') {
        displayInfo += '\n';
        displayInfo += 'Driver name: ';
        displayInfo += driverName;
      }

      if (insurer && insurer != '') {
        displayInfo += '\n';
        displayInfo += 'Insurer: ';
        displayInfo += insurer;
      }

      if (policyNumber && policyNumber != '') {
        displayInfo += '\n';
        displayInfo += 'Policy no.: ';
        displayInfo += policyNumber;
      }

      if (homeDepot && homeDepot != '') {
        displayInfo += '\n';
        displayInfo += 'Home depot: ';
        displayInfo += homeDepot;
      }

      return displayInfo;
    }.property('serial', 'licensePlate', 'driverName', 'insurer', 'policyNumber', 'homeDepot'),
    vbat: _emberData.default.attr('string', {
      readOnly: true
    }),
    temperature: _emberData.default.attr('string', {
      readOnly: true
    }),
    kl30: _emberData.default.attr('string', {
      readOnly: true
    }),
    gptxtCounter: _emberData.default.attr('string', {
      readOnly: true
    }),
    vbatDisplay: function () {
      var vbat = this.get('vbat');
      vbat = vbat / 1000;
      return vbat.toString() + ' V';
    }.property('vbat'),
    displayVbat: function () {
      var vbat = this.get('vbat');
      return vbat != 0;
    }.property('vbat'),
    externalVCC: function () {
      var kl30 = this.get('kl30');
      kl30 = kl30 / 1000;
      return kl30.toString() + ' V';
    }.property('kl30'),
    displayKl30: function () {
      var kl30 = this.get('kl30');
      return kl30 != 0;
    }.property('kl30'),
    displayAdditionalHardware: function () {
      var hdw = this.get('additionalHardware');
      return hdw != 0;
    }.property('additionalHardware'),
    checkProperties: function () {// console.log(this.get('vbat'));
      // console.log(this.get('temperature'));
      // console.log(this.get('kl30'));
      // console.log(this.get('gptxtCounter'));
    }.property('vbat', 'temperature', 'kl30', 'gptxtCounter'),
    // configId: DS.attr('string')
    firmwareOk: function () {
      var str = this.get('fwVersion');

      if (!str) {
        return false;
      }

      str = str.substring(2, 9);

      if (Number(str) >= 3.5) {
        return true;
      } else {
        return false;
      }
    }.property('fwVersion'),
    isEverecDevice: Ember.computed('serial', function () {
      var serial = this.get('serial');
      return serial < '3' && serial.length == 8;
    }),
    isNexgenDevice: Ember.computed('serial', function () {
      var serial = this.get('serial');
      return serial >= '8000000' && serial <= '99999999' && serial.length == 8;
    }),
    hasConfiguration: Ember.computed('serial', function () {
      return this.get('isNexgenDevice') || this.get('isEverecDevice');
    }),
    firmwareHasPreview: Ember.computed('fwVersion', 'isNexgenDevice', function () {
      return this.get('fwVersion') >= '1.8.0' || this.get('isNexgenDevice');
    }),
    isMDVRDevice: Ember.computed('serial', function () {
      var serial = this.get('serial');

      if (serial == null) {
        return false;
      }

      return serial.startsWith('M');
    }),
    camera2: Ember.computed('cameras', function () {
      return this.get('cameras') > 1 || this.get('isNexgenDevice');
    }),
    isLiveCheckAvailable: function () {
      return this.get('state') == 'online' && this.get('fwVersion') && (this.get('fwVersion') >= '1.8.0' || this.get('isNexgenDevice')) && ['normal', 'undefined'].includes(this.get('operatingMode'));
    }.property('fwBuild', 'state', 'operatingMode'),
    setupValidationDate: _emberData.default.attr('string', {
      readOnly: true
    }),
    setupValidationUser: _emberData.default.attr('string', {
      readOnly: true
    }),
    setupConfirmationCode: _emberData.default.attr('string', {
      readOnly: true
    }),
    baseImage: _emberData.default.attr('string', {
      readOnly: true
    }),
    regionImage: _emberData.default.attr('string', {
      readOnly: true
    })
  });

  var _default = Device;
  _exports.default = _default;
});