define("vts-ember/services/store", ["exports", "ember-data", "vts-ember/adapters/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var StoreService = _emberData.default.Store.extend({
    adapter: _application.default
  });

  var _default = StoreService;
  _exports.default = _default;
});