define("vts-ember/controllers/error-status", ["exports", "vts-ember/controllers/devices"], function (_exports, _devices) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ErrorMainController = _devices.default.extend({
    application: Ember.inject.controller(),
    queryParams: ['search', 'sortWith', 'order'],
    sortBy: 'lastConnectedFormatted',
    sortAscending: false,
    filterParams: ['serial', 'fwVersion', 'fwBuild', 'lastConnectedFormatted', 'error', 'errorTimeFormatted', 'stateTooltip'],
    sortByLastConnectedFormatted: function () {
      return this.get('sortBy') == 'lastConnectedFormatted';
    }.property('sortBy'),
    sortByError: function () {
      return this.get('sortBy') == 'error';
    }.property('sortBy'),
    sortByErrorTimeFormatted: function () {
      return this.get('sortBy') == 'errorTimeFormatted';
    }.property('sortBy'),
    filteredContent: function () {
      var ac = this.get('model');

      if (ac) {
        var fc = ac.filter(function (_this) {
          return function (theObject, index, enumerable) {
            if (!theObject.get('hasError')) {
              return false;
            }

            if (_this.get('theFilter')) {
              return _this.checkFilterMatch(theObject, _this.get('theFilter'));
            } else {
              return true;
            }
          };
        }(this));
        this.set('recordsNo', fc.length);
        return fc;
      } else {
        return null;
      }
    }.property('arrangedContent', 'recordsNo', 'theFilter', 'sortBy', 'sortAscending', 'content.[]')
  });

  var _default = ErrorMainController;
  _exports.default = _default;
});