define("vts-ember/models/project-config", ["exports", "ember-data", "vts-ember/models/device-config"], function (_exports, _emberData, _deviceConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ProjectConfig = _deviceConfig.default.extend({
    active: _emberData.default.attr('boolean')
  });

  var _default = ProjectConfig;
  _exports.default = _default;
});