define("vts-ember/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ApplicationController = Ember.Controller.extend({
    devices: Ember.inject.controller(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    queryParams: ['projectId'],
    canAddProjects: false,
    canEditProject: false,
    canAddUsers: false,
    canAddAdminUser: false,
    canAddDevices: false,
    canConfigDevice: false,
    canConfigDeviceSuperuser: false,
    noFullMap: true,
    projectsAllowed: false,
    usersAllowed: false,
    logsAllowed: false,
    statisticsAllowed: false,
    videoRequestsAllowed: false,
    develStatistics: false,
    canViewTripDelay: false,
    canEditDeviceHistory: false,
    lastSignIn: '',
    search: null,
    setUnit: 'km',
    selectedDevices: [],
    project: Ember.inject.service(),
    inView: function () {
      return window.location !== window.parent.location;
    }.property('window'),
    actions: {
      listDevices: function listDevices() {
        var router = this.get('target');
        var url = router.get('url');

        if (url == '/devices') {
          router.handleURL(url);
        } else {
          this.transitionToRoute('devices.index');
        }
      },
      searchDevices: function searchDevices() {
        this.transitionToRoute('devices', {
          queryParams: {
            search: this.get('search')
          }
        }); // TODO: find a better way to clear selected devices, leave the old value in the search box for now to support legacy workflows
        // this.set('search', '')
      },
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      }
    }
  });
  var _default = ApplicationController;
  _exports.default = _default;
});