define("vts-ember/templates/users/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iuyK37Tc",
    "block": "{\"symbols\":[],\"statements\":[[6,\"form\"],[9,\"class\",\"well form-horizontal\"],[9,\"novalidate\",\"\"],[3,\"action\",[[19,0,[]],\"save\"],[[\"on\"],[\"submit\"]]],[7],[0,\"\\n  \"],[6,\"legend\"],[7],[0,\"User information\"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"isEditing\"]]],null,{\"statements\":[[0,\"      \"],[12,\"users/user_edit_fields\",[]],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"form-group\"],[7],[0,\"\\n        \"],[6,\"p\"],[9,\"class\",\"nav pull-right\"],[7],[0,\"\\n          \"],[6,\"button\"],[9,\"class\",\"btn btn-default\"],[3,\"action\",[[19,0,[]],\"cancel\"]],[7],[0,\"Cancel\"],[8],[0,\"\\n          \"],[6,\"button\"],[9,\"type\",\"submit\"],[9,\"class\",\"btn btn-primary\"],[7],[0,\"Update\"],[8],[0,\"\\n        \"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"canDelete\"]]],null,{\"statements\":[[0,\"          \"],[6,\"p\"],[9,\"class\",\"nav pull-left\"],[7],[0,\"\\n            \"],[6,\"button\"],[9,\"class\",\"btn btn-default\"],[9,\"type\",\"button\"],[3,\"action\",[[19,0,[]],\"destroyRecord\"]],[7],[0,\"Delete\"],[8],[0,\"\\n          \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[12,\"users/user_show_fields\",[]],[0,\"\\n\"]],\"parameters\":[]}],[8],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "vts-ember/templates/users/user.hbs"
    }
  });

  _exports.default = _default;
});