define("vts-ember/templates/project/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/lV27sfh",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"isEditing\"]]],null,{\"statements\":[[0,\"  \"],[12,\"project/edit\",[]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[12,\"project/show\",[]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":true}",
    "meta": {
      "moduleName": "vts-ember/templates/project/index.hbs"
    }
  });

  _exports.default = _default;
});