define("vts-ember/routes/offline", ["exports", "vts-ember/routes/devices"], function (_exports, _devices) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _devices.default.extend({});

  _exports.default = _default;
});