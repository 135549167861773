define("vts-ember/controllers/video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      close: function close() {
        history.back();
      },
      trackClicked: function trackClicked(startDate) {
        this.set('start', startDate);
        var offset = startDate - this.get('model.start');
        this.set('offset', offset);
      },
      timeUpdate: function timeUpdate(time) {
        this.set('start', new Date(new Date(this.get('model.start')).getTime() + parseInt(time) * 1000));
      }
    }
  });

  _exports.default = _default;
});