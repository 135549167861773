define("vts-ember/templates/-pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6pwgyM+F",
    "block": "{\"symbols\":[],\"statements\":[[6,\"span\"],[9,\"class\",\"pull-right\"],[7],[0,\"\\n  \"],[6,\"ul\"],[9,\"class\",\"pagination\"],[7],[0,\"\\n    \"],[6,\"li\"],[10,\"class\",[26,[[25,\"if\",[[20,[\"disablePrevious\"]],\"disabled\"],null]]]],[7],[0,\"\\n      \"],[6,\"button\"],[10,\"class\",[26,[\"btn btn-sm btn-primary \",[25,\"if\",[[20,[\"disablePrevious\"]],\"disabled\"],null]]]],[9,\"type\",\"button\"],[3,\"action\",[[19,0,[]],\"previous\"]],[7],[0,\"←\"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"li\"],[9,\"class\",\"active\"],[7],[0,\"\\n      \"],[6,\"button\"],[10,\"class\",[26,[\"btn btn-sm btn-primary \",[25,\"if\",[[20,[\"disablePrevious\"]],\"disabled\"],null]]]],[9,\"type\",\"button\"],[3,\"action\",[[19,0,[]],\"first\"]],[7],[0,\"1\"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"li\"],[9,\"class\",\"active\"],[7],[0,\"\\n      \"],[1,[25,\"input\",null,[[\"value\",\"placeholder\",\"class\",\"enter\"],[[20,[\"goto\"]],\"1\",\"button-input btn-sm\",\"goPage\"]]],false],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"li\"],[9,\"class\",\"disabled\"],[7],[0,\"\\n      \"],[6,\"button\"],[9,\"class\",\"btn btn-sm btn-primary disabled\"],[9,\"type\",\"button\"],[7],[0,\"\\n        of\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"li\"],[7],[0,\"\\n      \"],[6,\"button\"],[10,\"class\",[26,[\"btn btn-sm btn-primary \",[25,\"if\",[[20,[\"disableNext\"]],\"disabled\"],null]]]],[9,\"type\",\"button\"],[3,\"action\",[[19,0,[]],\"last\"]],[7],[1,[18,\"pagesNo\"],false],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"li\"],[7],[0,\"\\n      \"],[6,\"button\"],[10,\"class\",[26,[\"btn btn-sm btn-primary \",[25,\"if\",[[20,[\"disableNext\"]],\"disabled\"],null]]]],[9,\"type\",\"button\"],[3,\"action\",[[19,0,[]],\"next\"]],[7],[0,\"→\"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "vts-ember/templates/-pagination.hbs"
    }
  });

  _exports.default = _default;
});