define("vts-ember/components/incident-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var IncidentMapComponent = Ember.Component.extend({
    map: null,
    gpsData: null,
    markerPoints: [],
    incident: null,
    addMinutes: function addMinutes(date, minutes) {
      return new Date(date.getTime() + minutes * 60000);
    },
    createMarkers: function createMarkers(timestamp) {
      this.clearOverlay();
      var latLng = null;
      var bounds = new google.maps.LatLngBounds();
      latLng = new google.maps.LatLng(this.gpsData.get('firstObject.latitude'), this.gpsData.get('firstObject.longitude'));
      this.markerPoints.push(this.createMarker(latLng, 'https://www.google.com/mapfiles/dd-start.png'));
      bounds.extend(latLng);
      latLng = new google.maps.LatLng(this.gpsData.get('lastObject.latitude'), this.gpsData.get('lastObject.longitude'));
      this.markerPoints.push(this.createMarker(latLng, 'https://www.google.com/mapfiles/dd-end.png'));
      bounds.extend(latLng);

      var _this = this;

      this.gpsData.forEach(function (gps) {
        if (timestamp != null) {
          var gpsTime = gps.get('time').toString();

          if (timestamp == gpsTime) {
            latLng = new google.maps.LatLng(gps.get('latitude'), gps.get('longitude'));

            _this.markerPoints.push(_this.createMarker(latLng, {
              url: 'https://maps.google.com/mapfiles/arrow.png',
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(12, 32)
            }, gps.get('timeFormatted')));
          } else {
            latLng = new google.maps.LatLng(gps.get('latitude'), gps.get('longitude'));

            _this.markerPoints.push(_this.createMarkerPoint(latLng, gps.get('timeFormatted')));
          }
        }

        bounds.extend(latLng);
      });
      this.bounds = bounds;
      google.maps.event.addListenerOnce(this.map, 'idle', function () {
        _this.map.fitBounds(_this.bounds);
      });
    },
    createMarkerPoint: function createMarkerPoint(point, title) {
      return new google.maps.Marker({
        position: point,
        title: title,
        icon: {
          strokeColor: this.get('tripsColor'),
          path: google.maps.SymbolPath.CIRCLE,
          scale: 2
        },
        map: this.get('map'),
        optimized: false
      });
    },
    createMarker: function createMarker(latlng, icon, title) {
      return new google.maps.Marker({
        position: latlng,
        title: title,
        map: this.get('map'),
        icon: icon,
        optimized: false
      });
    },
    deleteMarkers: function deleteMarkers() {
      this.markerPoints.forEach(function (marker) {
        marker.setMap(null);
      });
      this.markerPoints = [];
    },
    clearOverlay: function clearOverlay() {
      this.deleteMarkers();
      this.markerPoints.forEach(function (marker) {
        marker.setMap(null);
      });
      this.markerPoints = [];
    },
    didInsertElement: function didInsertElement() {
      var mapOptions = {
        zoom: 4,
        center: new google.maps.LatLng(52.271567, 10.54617628220852),
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
        },
        panControl: true,
        zoomControl: true,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.SMALL,
          position: google.maps.ControlPosition.TOP_LEFT
        },
        scaleControl: true,
        streetViewControl: false,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP
        },
        navigationControl: true,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      };
      this.set('map', new google.maps.Map(this.$().get(0), mapOptions));
      this.$().css({
        width: '100%',
        height: '600px'
      });
      var timestamp = this.get('incident.timestamp');

      if (timestamp) {
        this.createMarkers(timestamp.toString());
      }

      google.maps.event.trigger(this.map, 'resize');
    },
    willDestroyElement: function willDestroyElement() {}
  });
  var _default = IncidentMapComponent;
  _exports.default = _default;
});