define("vts-ember/templates/components/video-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "46mui507",
    "block": "{\"symbols\":[\"p\"],\"statements\":[[6,\"td\"],[7],[0,\"\\n    \"],[1,[20,[\"video\",\"startFormatted\"]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"td\"],[7],[0,\"\\n    \"],[1,[20,[\"video\",\"alarmType\"]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"td\"],[7],[0,\"\\n    \"],[1,[20,[\"video\",\"camera\"]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"td\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"video\",\"isIncomplete\"]]],null,{\"statements\":[[4,\"bs-progress\",null,null,{\"statements\":[[0,\"        \"],[1,[25,\"component\",[[19,1,[\"bar\"]]],[[\"value\",\"minValue\",\"maxValue\",\"showLabel\",\"striped\"],[[20,[\"video\",\"loadProgress\"]],0,100,true,true]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[20,[\"video\",\"state\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[8],[0,\"\\n\"],[6,\"td\"],[7],[0,\"\\n    \"],[1,[20,[\"video\",\"duration\"]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"td\"],[7],[0,\"\\n    \"],[1,[25,\"video-link\",null,[[\"video\",\"showLink\"],[[20,[\"video\"]],[20,[\"showLink\"]]]]],false],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "vts-ember/templates/components/video-row.hbs"
    }
  });

  _exports.default = _default;
});