define("vts-ember/components/map-index", ["exports", "vts-ember/utils/marker-with-label"], function (_exports, _markerWithLabel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MapIndexComponent = Ember.Component.extend({
    router: Ember.inject.service(),
    map: null,
    trafficLayer: null,
    markerArray: [],
    markerPoints: [],
    index: null,
    initialInserted: true,
    showLabels: true,
    showTraffic: false,
    bounds: null,
    devicesLoaded: function () {
      this.createAllMarkers();
    }.observes('index', 'showLabels'),
    traffic: function () {
      var showTraffic = this.get('showTraffic');
      var trafficLayer = this.get('trafficLayer');

      if (showTraffic) {
        trafficLayer.setMap(this.get('map'));
      } else {
        trafficLayer.setMap(null);
      }
    }.observes('showTraffic'),
    createAllMarkers: function createAllMarkers(view) {
      this.clearOverlay();
      var self = this;
      var latLng = null;
      var bounds = new google.maps.LatLngBounds();
      var index = this.get('index');

      if (index == null) {
        return;
      }

      var len = this.get('index').length;
      var showLabels = this.get('showLabels');

      var _this = this;

      index.forEach(function (device) {
        var latitude = device.get('latitude');
        var longitude = device.get('longitude');
        latLng = null;

        if (latitude != 0 || longitude != 0) {
          latLng = new google.maps.LatLng(device.get('latitude'), device.get('longitude'));
        }

        if (latLng != null) {
          var icon = '';
          var state = device.get('eventState');

          if (state == 'online') {
            icon = 'http://labs.google.com/ridefinder/images/mm_20_green.png';
          } else if (state === 'offline' || state === 'disconnected') {
            icon = 'http://labs.google.com/ridefinder/images/mm_20_blue.png';
          } else if (state == 'critical') {
            icon = 'http://labs.google.com/ridefinder/images/mm_20_red.png';
          } else if (state == 'harsh') {
            icon = 'http://labs.google.com/ridefinder/images/mm_20_yellow.png';
          } else {
            icon = 'http://labs.google.com/ridefinder/images/mm_20_gray.png';
          }

          var url = _this.get('router').urlFor('device', device);

          var license_plate = device.get('licensePlate');
          var marker;

          if (showLabels && len < 1000) {
            marker = self.createMarker(latLng, device.get('mapToolTip'), icon, url, license_plate);
          } else {
            marker = self.createMarker(latLng, device.get('mapToolTip'), icon, url, null);
          }

          self.markerPoints.push(marker);
          bounds.extend(latLng);
        }
      });
      /* apparently when hitting the map view the content (devices) can get updated several
       * times in short succession. The re-centering of the map is now done within 10 seconds
       * of it being inserted in the view
       */

      if (typeof this.initialInserted === 'boolean') {
        this.initialInserted = Date.now();
      }

      if (Date.now() - this.initialInserted < 10000) {
        if (len > 0) {
          this.get('map').fitBounds(bounds);
        } else {
          this.get('map').setCenter({
            lat: 0,
            lng: 0
          });
        }
      }

      this.set('bounds', bounds);
    },
    createMarker: function createMarker(latlng, title, icon, url, license_plate) {
      var marker;

      if (license_plate) {
        marker = new _markerWithLabel.default({
          position: latlng,
          map: this.get('map'),
          icon: icon,
          title: title,
          url: url,
          labelContent: license_plate,
          labelAnchor: new google.maps.Point(0, 0),
          labelClass: 'labels',
          // the CSS class for the label
          labelStyle: {
            opacity: 0.75
          }
        });
      } else {
        marker = new google.maps.Marker({
          position: latlng,
          map: this.get('map'),
          icon: icon,
          title: title,
          url: url
        });
      }

      google.maps.event.addListener(marker, 'click', function () {
        window.location.href = marker.url;
      });
      return marker;
    },
    deleteMarkers: function deleteMarkers() {
      this.markerArray.forEach(function (marker) {
        marker.setMap(null);
      });
      this.markerArray = [];
    },
    clearOverlay: function clearOverlay() {
      this.deleteMarkers();
      this.markerPoints.forEach(function (marker) {
        marker.setMap(null);
      });
      this.markerPoints = [];
    },
    didInsertElement: function didInsertElement() {
      var mapOptions = {
        zoom: 2,
        maxZoom: 20,
        minZoom: 2,
        center: new google.maps.LatLng(45.271567, 10.54617628220852),
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
        },
        zoomControl: true,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.SMALL,
          position: google.maps.ControlPosition.TOP_LEFT
        },
        panControl: true,
        scaleControl: true,
        streetViewControl: false,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP
        },
        navigationControl: true,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        fullscreenControl: true
      };
      var map = new google.maps.Map(this.$().get(0), mapOptions);
      var trafficLayer = new google.maps.TrafficLayer();
      var self = this;
      trafficLayer.setMap(null);
      this.set('map', map);
      this.set('trafficLayer', trafficLayer);
      this.initialInserted = true;
      this.$().css({
        width: '100%',
        height: '70vh'
      });
      google.maps.event.trigger(this.map, 'resize');
      google.maps.event.addDomListener(window, 'resize', function () {
        var bounds = self.get('bounds');

        if (bounds) {
          map.fitBounds(bounds);
        }
      });
      this.createAllMarkers();
    },
    willDestroyElement: function willDestroyElement() {
      this.initialInserted = true;
    },
    willClearRender: function willClearRender() {
      this.deleteMarkers();
    }
  });
  var _default = MapIndexComponent;
  _exports.default = _default;
});