define("vts-ember/models/video-state-incomplete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VIDEO_STATE_INCOMPLETE = 'incomplete';
  var _default = VIDEO_STATE_INCOMPLETE;
  _exports.default = _default;
});