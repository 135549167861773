define("vts-ember/controllers/projects/index", ["exports", "vts-ember/controllers/tables"], function (_exports, _tables) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ProjectsIndexController = _tables.default.extend({
    application: Ember.inject.controller(),
    devices: Ember.inject.controller(),
    project: Ember.inject.service(),
    users: Ember.inject.controller(),
    sortBy: 'projectId',
    sortAscending: true,
    filterParams: ['projectId', 'projectName', 'billingCode'],
    activeId: null,
    assignSelect: null,
    isEditable: false,

    /* ugly bunch of functions used to display search direction arrows on the correct column only */
    sortByProjectId: function () {
      return this.get('sortBy') == 'projectId';
    }.property('sortBy'),
    sortByProjectName: function () {
      return this.get('sortBy') == 'projectName';
    }.property('sortBy'),
    sortByProjectDevicesNo: function () {
      return this.get('sortBy') == 'devicesNo';
    }.property('sortBy'),
    sortByBillingCode: function sortByBillingCode() {
      return this.get('sortBy') == 'billingCode';
    },
    sortByExtendedWarranty: function sortByExtendedWarranty() {
      return this.get("sortBy") == "extendedWarranty";
    },
    sortByCustomerName: function () {
      return this.get('sortBy') == 'customerName';
    }.property('sortBy'),
    sortByPlatform: function () {
      return this.get('sortBy') == 'platform';
    }.property('sortBy'),
    getSelectedIDs: function getSelectedIDs() {
      // console.log("Warning: projects_controller.getSelectedIDs() was called")
      return this.get('project.project.id');
    },
    actions: {
      deleteProject: function deleteProject(project) {
        if (window.confirm("Are you sure you want to delete the project '" + project.id + "' and all associated devices and users?")) {
          project.destroyRecord();
        }
      },
      deselectProject: function deselectProject() {
        this.get('project').setSelected(null);
        this.set('application.model', null);
        this.set('application.projectId', null);
        this.get('devices').clearSelection();
        this.get('users').clearSelection();
      }
    }
  });

  var _default = ProjectsIndexController;
  _exports.default = _default;
});