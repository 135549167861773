define("vts-ember/templates/components/video-request-device", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pZ9LAbdZ",
    "block": "{\"symbols\":[],\"statements\":[[6,\"td\"],[7],[0,\"\\n  \"],[6,\"button\"],[9,\"type\",\"button primary\"],[10,\"class\",[26,[[20,[\"device\",\"onlineBtn\"]]]]],[10,\"title\",[26,[[20,[\"device\",\"onlineTooltip\"]]]]],[7],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"td\"],[7],[0,\"\\n  \"],[1,[20,[\"device\",\"licensePlate\"]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"td\"],[7],[0,\"\\n  \"],[1,[20,[\"device\",\"serial\"]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"td\"],[7],[0,\"\\n  \"],[1,[20,[\"device\",\"zone\"]],false],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "vts-ember/templates/components/video-request-device.hbs"
    }
  });

  _exports.default = _default;
});