define("vts-ember/controllers/projects/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ProjectsNewController = Ember.Controller.extend({
    startEditing: function startEditing() {
      this.stopEditing();
      this.set('model', this.store.createRecord('project'));
    },
    stopEditing: function stopEditing() {
      var model = this.get('model');

      if (model) {
        model.rollbackAttributes();
        this.set('model', null);
      }
    },
    addSucceeded: function addSucceeded(project) {
      this.transitionToRoute('project', project);
    },
    addFailed: function addFailed(response) {
      console.log("adding project failed:" + response);
      window.alert('Project ID already exists. Please choose a different one!');
    },
    actions: {
      save: function save() {
        var _this = this;

        if (this.get('model.validationError')) {
          window.alert('Please fill out the whole form.');
        } else {
          // check if exist alert
          var projectExist = this.store.peekRecord('project', this.get('model.projectId'));

          if (projectExist != null) {
            window.alert('Project ID already exists. Please choose a different one!');
          } else {
            var project = this.get('model');
            this.get('model').save().then(function (project) {
              _this.addSucceeded(project);
            }).catch(function (response) {
              _this.addFailed(response);
            });
          }
        }
      },
      cancel: function cancel() {
        this.stopEditing();
        this.transitionToRoute('projects.index');
      }
    }
  });
  var _default = ProjectsNewController;
  _exports.default = _default;
});