define("vts-ember/controllers/users", ["exports", "vts-ember/controllers/tables"], function (_exports, _tables) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var UsersController = _tables.default.extend({
    application: Ember.inject.controller(),
    currentUser: Ember.inject.service(),
    sortBy: 'email',
    sortAscending: true,
    allSelected: false,
    isEditable: false,
    isNotNew: true,
    filterParams: ['email', 'name', 'role', 'projectId'],

    /* ugly bunch of functions used to display search direction arrows on the correct column only */
    sortByEmail: function () {
      return this.get('sortBy').startsWith('email');
    }.property('sortBy'),
    sortByName: function () {
      return this.get('sortBy').startsWith('name');
    }.property('sortBy'),
    sortByRole: function () {
      return this.get('sortBy').startsWith('role');
    }.property('sortBy'),
    sortByProject: function () {
      return this.get('sortBy').startsWith('project');
    }.property('sortBy'),
    _isCheckedChanged: function () {
      var filteredUsers = this.get("filteredContent");

      if (this.get("allSelected")) {
        filteredUsers.forEach(function (user) {
          user.set('isSelected', true);
        });
      } else {
        filteredUsers.forEach(function (user) {
          user.set("isSelected", false);
        });
      }
    }.observes('allSelected'),
    clearSelection: function clearSelection() {
      this.store.peekAll('user').filterBy('isSelected', true).forEach(function (user) {
        user.set('isSelected', false);
      });
    },
    actions: {
      deleteUsers: function deleteUsers() {
        var cuser = this.get('currentUser');

        if (window.confirm('Are you sure you want to delete the selected users?')) {
          this.store.peekAll('user').filterBy('isSelected', true).forEach(function (user) {
            var canDelete = true;
            console.log('going to delete user ' + user.get('email'));

            if (user.get('email') == cuser.get('email')) {
              canDelete = false;
            }

            if (cuser.get('role') == 'project admin' && user.get('role') == 'admin') {
              // console.log('admin cannot be deleted by project admin!');
              canDelete = false;
            }

            if (canDelete) {
              console.log('deleting user ' + user.get('email'));
              user.destroyRecord();
            }
          });
        }
      }
    }
  });

  var _default = UsersController;
  _exports.default = _default;
});