define("vts-ember/routes/devices", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    queryParams: {
      search: {
        refreshModel: true
      },
      sortWith: {
        refreshModel: true
      },
      order: {
        refreshModel: true
      }
    },
    project: Ember.inject.service(),
    model: function model(params) {
      if (this.modelFor('application')) {
        params.projectId = this.modelFor('application');
      } else {
        params.projectId = this.get('project.project.id');
      }

      return this.store.query('device', params);
    },
    setupController: function setupController(controller, model) {
      controller.set('projectId', this.get('project.project.id'));
      controller.set('currentPage', 1);

      this._super(controller, model);

      controller.set('recordsNo', model.get('length'));
    },
    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});