define("vts-ember/routes/config-requests/new", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      // Because we are maintaining a transaction locally in the controller for editing,
      // the new record needs to be created in the controller.
      return this.store.findRecord('deviceConfig', params.config_id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('deviceConfig', model);
      controller.startEditing();
    },
    deactivate: function deactivate() {
      this.controllerFor('configRequest.new').stopEditing();
    }
  });

  _exports.default = _default;
});