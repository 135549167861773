define("vts-ember/controllers/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var IndexController = Ember.Controller.extend({
    devices: Ember.inject.controller(),
    application: Ember.inject.controller(),
    project: Ember.inject.service(),
    projects: null,
    showLicensePlates: false,
    showTraffic: false,
    selection: 'all',
    contentFiltered: function () {
      var content = this.get('model');

      if (content) {
        var _this = this;

        return content.filter(function (item, index) {
          switch (_this.get('selection')) {
            case 'online':
              if (item.get('state') == 'online') {
                return item;
              }

              break;

            case 'offline':
              if (item.get('state') == 'offline') {
                return item;
              }

              break;

            default:
              return item;
          }
        });
      } else {
        return null;
      }
    }.property('model.isLoaded', 'selection'),
    actions: {
      refresh: function refresh() {
        console.log("refresh in index controller");
        var router = this.get('target');
        router.handleURL(router.get('url'));
      }
    }
  });
  var _default = IndexController;
  _exports.default = _default;
});