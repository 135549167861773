define("vts-ember/routes/incident", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      return this.store.findRecord('incident', params.incident_id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (model.get('state') != 'seen') {
        model.set('state', 'seen');
        model.save();
      }
    },
    afterModel: function afterModel(incident) {
      var controller = this.controllerFor('incident');
      var before = this.addMinutes(incident.get('timestamp'), -1);
      var after = this.addMinutes(incident.get('timestamp'), 1);
      var videos = this.store.query('video', {
        device: incident.get('device.id'),
        start: before,
        stop: after
      }).then(function (v) {
        controller.set('videos', v);
      });
      var gps = this.store.query('gpsDatum', {
        device_id: incident.get('device.id'),
        start: before,
        stop: after
      }).then(function (g) {
        controller.set('gpsData', g);
      });
      var accel = this.store.query('accel', {
        device: incident.get('device.id'),
        start: before,
        stop: after
      }).then(function (accelData) {
        var arrX = [];
        var arrY = [];
        var arrZ = [];
        var timestamps = [];
        var incidentTimestamp = incident.get('timestamp').toString();
        var index = 0;
        var incidentIndex = 0;
        accelData.forEach(function (ac) {
          var timestamp = ac.get('timestamp');
          arrX.push(ac.get('xAccel'));
          arrY.push(ac.get('yAccel'));
          arrZ.push(ac.get('zAccel'));
          timestamps.push(timestamp.toLocaleString());

          if (timestamp.toString() == incidentTimestamp) {
            controller.set('chartoptions.incidentIndex', index);
          }

          index++;
        });
        controller.set('accelDataX', arrX);
        controller.set('accelDataY', arrY);
        controller.set('accelDataZ', arrZ);
        controller.set('timestamps', timestamps);
      });
      return Ember.RSVP.hash({
        videos: videos,
        gps: gps,
        accel: accel
      });
    },
    addMinutes: function addMinutes(date, minutes) {
      return new Date(date.getTime() + minutes * 60000);
    }
  });

  _exports.default = _default;
});