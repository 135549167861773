define("vts-ember/services/devices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Devices = Ember.Service.extend({
    selected: null
  });
  var _default = Devices;
  _exports.default = _default;
});