define("vts-ember/templates/components/from-to-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XVkSwFHm",
    "block": "{\"symbols\":[],\"statements\":[[6,\"label\"],[7],[0,\"\\n\\tFrom\\n\\t\"],[1,[25,\"ember-flatpickr\",null,[[\"allowInput\",\"date\",\"dateFormat\",\"defaultDate\",\"enableTime\",\"maxDate\",\"minuteIncrement\",\"onChange\",\"time_24hr\",\"class\"],[true,[20,[\"start\"]],\"Y-m-d H:i\",[20,[\"start\"]],true,[20,[\"maxDate\"]],1,[25,\"action\",[[19,0,[]],\"startChanged\"],null],true,\"form-control\"]]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"label\"],[7],[0,\"\\n\\tTo\\n\\t\"],[1,[25,\"ember-flatpickr\",null,[[\"allowInput\",\"date\",\"dateFormat\",\"defaultDate\",\"enableTime\",\"maxDate\",\"minuteIncrement\",\"onChange\",\"time_24hr\",\"class\"],[true,[20,[\"end\"]],\"Y-m-d H:i\",[20,[\"end\"]],true,[20,[\"maxDate\"]],1,[25,\"action\",[[19,0,[]],\"endChanged\"],null],true,\"form-control\"]]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[4,\"bs-button-group\",null,null,{\"statements\":[[0,\"  \"],[1,[25,\"bs-button\",null,[[\"defaultText\",\"icon\",\"onClick\"],[\"Filter\",\"glyphicon glyphicon-filter\",[25,\"action\",[[19,0,[]],\"filter\"],null]]]],false],[0,\"\\n  \"],[1,[25,\"bs-button\",null,[[\"icon\",\"onClick\"],[\"glyphicon glyphicon-remove-sign\",[25,\"action\",[[19,0,[]],\"clear_filter\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "vts-ember/templates/components/from-to-picker.hbs"
    }
  });

  _exports.default = _default;
});