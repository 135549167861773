define("vts-ember/templates/components/device-in-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rUPQ935v",
    "block": "{\"symbols\":[],\"statements\":[[6,\"td\"],[7],[0,\"\\n  \"],[1,[25,\"input\",null,[[\"type\",\"name\",\"checked\"],[\"checkbox\",\"check_list\",[20,[\"device\",\"isSelected\"]]]]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"td\"],[7],[0,\"\\n\"],[4,\"link-to\",[\"device\",[20,[\"device\"]]],null,{\"statements\":[[0,\"    \"],[1,[20,[\"device\",\"serial\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\\n\"],[6,\"td\"],[7],[0,\"\\n  \"],[1,[20,[\"device\",\"fwVersion\"]],false],[0,\" \"],[1,[20,[\"device\",\"fwBuild\"]],false],[0,\"\\n\"],[8],[0,\"\\n\\n\"],[6,\"td\"],[7],[0,\"\\n  \"],[1,[20,[\"device\",\"licensePlate\"]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"td\"],[7],[0,\"\\n  \"],[1,[20,[\"device\",\"zone\"]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"td\"],[7],[0,\"\\n  \"],[1,[20,[\"device\",\"homeDepot\"]],false],[0,\"\\n\"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"showProject\"]]],null,{\"statements\":[[6,\"td\"],[7],[0,\"\\n  \"],[1,[20,[\"device\",\"projectId\"]],false],[0,\"\\n\"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[6,\"td\"],[7],[0,\"\\n  \"],[6,\"button\"],[9,\"type\",\"button\"],[10,\"class\",[26,[[20,[\"device\",\"stateBtn\"]]]]],[7],[0,\"\\n    \"],[1,[25,\"bs-tooltip\",null,[[\"title\",\"placement\",\"delayShow\",\"delayHide\"],[[20,[\"device\",\"stateTooltip\"]],\"left\",\"200\",\"100\"]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "vts-ember/templates/components/device-in-list.hbs"
    }
  });

  _exports.default = _default;
});