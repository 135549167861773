define("vts-ember/templates/video-request/complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZBw1n/b/",
    "block": "{\"symbols\":[\"video\"],\"statements\":[[4,\"if\",[[20,[\"model\"]]],null,{\"statements\":[[6,\"h3\"],[7],[0,\"Complete requests\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"table-responsive\"],[7],[0,\"\\n  \"],[6,\"table\"],[9,\"class\",\"table table-hover\"],[7],[0,\"\\n    \"],[6,\"thead\"],[7],[0,\"\\n      \"],[6,\"tr\"],[7],[0,\"\\n        \"],[6,\"th\"],[7],[0,\" Device \"],[8],[0,\"\\n        \"],[6,\"th\"],[7],[0,\" Start \"],[8],[0,\"\\n        \"],[6,\"th\"],[7],[0,\" Duration \"],[8],[0,\"\\n        \"],[6,\"th\"],[7],[0,\" Camera \"],[8],[0,\"\\n        \"],[6,\"th\"],[7],[0,\" Upload status \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"tbody\"],[9,\"class\",\"text-nowrap\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"model\"]]],null,{\"statements\":[[0,\"        \"],[6,\"tr\"],[7],[0,\"\\n          \"],[6,\"td\"],[7],[0,\" \"],[1,[19,1,[\"device\",\"serial\"]],false],[0,\" \"],[8],[0,\"\\n          \"],[6,\"td\"],[7],[0,\" \"],[1,[19,1,[\"startFormatted\"]],false],[0,\" \"],[8],[0,\"\\n          \"],[6,\"td\"],[7],[0,\" \"],[1,[19,1,[\"duration\"]],false],[0,\" \"],[8],[0,\"\\n          \"],[6,\"td\"],[7],[0,\" \"],[1,[19,1,[\"camera\"]],false],[0,\" \"],[8],[0,\"\\n          \"],[6,\"td\"],[7],[0,\"\\n\"],[4,\"if\",[[19,1,[\"isComplete\"]]],null,{\"statements\":[[4,\"link-to\",[\"video\",[19,1,[]]],null,{\"statements\":[[0,\"                View \"],[6,\"span\"],[9,\"class\",\"glyphicon glyphicon-play\"],[9,\"aria-hidden\",\"true\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"          \"],[8],[0,\" \\n        \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,\"h1\"],[7],[6,\"small\"],[7],[0,\"No completed requests\"],[8],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "vts-ember/templates/video-request/complete.hbs"
    }
  });

  _exports.default = _default;
});